import { Button, Flex, Menu, Popover, Tabs } from "antd";
import { Header } from "antd/es/layout/layout";
import "./AppHeader.css";
import "./PublicHeader.css";
import { MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useResponsive } from "../hooks/useResponsive";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const PublicHeader = () => {
  const { t } = useTranslation("index");
  const { isDesktop } = useResponsive();

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleSelect = (key: string) => {
    setOpen(false);
    navigate(`/${key}`);
  };

  const items = [
    {
      key: "cooperations",
      label: t("cooperations"),
    },
    {
      key: "projects",
      label: t("projects"),
    },
    {
      key: "scholarships",
      label: t("scholarships"),
    },
    {
      key: "offers",
      label: t("offers"),
    },
  ];

  const defaultSelectedKeys = [pathname.split("/")[1] || "cooperations"];

  return (
    <Header className="main-header">
      <Flex wrap="wrap" align="center">
        <img alt="fr" src="/logo-imf.png" />
        <span className="header-title">
          <h1 className="public-title">{t("university_cooperation")}</h1>
          <p>{t("ambassador_france")}</p>
        </span>
      </Flex>
      <div className="public-menu">
        <Flex align="center" justify="center">
          <img alt="ambassade france" src="/campus_france.png" />
          <img alt="fr" src="/institut-france.png" />
          {!isDesktop && (
            <Popover
              content={
                <Menu
                  mode="inline"
                  defaultSelectedKeys={defaultSelectedKeys}
                  onSelect={({ key }) => handleSelect(key)}
                  items={items}
                />
              }
              title="Menu"
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <Button type="text" icon={<MenuOutlined />} />
            </Popover>
          )}
        </Flex>
        {isDesktop && (
          <Tabs
            className="public-ant-tabs"
            defaultActiveKey={defaultSelectedKeys.at(0)}
            onChange={handleSelect}
            items={items}
          />
        )}
      </div>
    </Header>
  );
};
