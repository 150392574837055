import { DictionaryDataFragment, DictionaryTypeEnum } from "../graphql";

export type Option = {
  label: string;
  value: any;
  parentSlug?: string;
};
export const filTerDictionary = (
  type: DictionaryTypeEnum,
  dictionaries: DictionaryDataFragment[]
): Option[] => {
  return dictionaries
    .filter((d) => d.type === type)
    .map(({ id, value }) => ({
      label: value,
      value: id,
    }));
};

export const filterOption = (
  input: string,
  option?: { label: string; value: number }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
