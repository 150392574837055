import { Layout } from "antd";
import { Footer } from "antd/es/layout/layout";
import { Outlet } from "react-router";
import "./AdminLayout.css";
import "./PublicLayout.css";
import { PublicHeader } from "./PublicHeader";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

const PublicLayout = () => {
  const { t } = useTranslation("index");
  const year = new Date().getFullYear();
  return (
    <Layout>
      <PublicHeader />
      <Layout>
        <Content className="main-content public-main-content">
          <Outlet />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Copyright ©{year}
            <h4 color="primary" >{t("university_cooperation")}</h4>
          </Footer>
      </Layout>
    </Layout>
  );
};

export default PublicLayout;
