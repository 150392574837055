import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppAlert } from "../../components/common/AppAlert";
import { AppListBar } from "../../components/common/AppListBar";
import { Flex, Progress, Spin, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { StarOutlined } from "@ant-design/icons";
import {
  StatusEnum,
  useOfferUpdateMutation,
  useOffersQuery,
  useRemoveOfferMutation,
} from "../../graphql";
import { statusBar } from "../../constants/status-bar";
import AppSpiner from "../../components/common/AppSpiner";

export const OfferPage = () => {
  const { t } = useTranslation(["program", "index"]);
  const [active, setActive] = useState("0");
  const [api, contextHolder] = notification.useNotification();

  const [search, setSearch] = useState("");

  const { loading, data } = useOffersQuery({});

  const navigate = useNavigate();

  const offers = useMemo(() => {
    return (data?.offers || []).filter((c) => {
      const statusOk = c.status === statusBar[active];
      const titleOk = c.title.toLowerCase().includes(search.toLowerCase());
      return statusOk && titleOk;
    });
  }, [data, active, search]);

  const statistics = useMemo(() => {
    return (data?.offers || []).reduce(
      (acc, cur) => {
        acc[cur.status] += 1;
        return acc;
      },
      {
        [StatusEnum.Published]: 0,
        [StatusEnum.Archived]: 0,
        [StatusEnum.Draft]: 0,
      }
    );
  }, [data]);

  //Update data
  const [updateOffer, { loading: loadingUpdate }] = useOfferUpdateMutation({
    onError: (error) => {
      api.error({
        message: "Modification d'appel à la candidature",
        description: `Erreur : ${error?.message}`,
      });
    },
  });

  const onArchive = (id: number, status: StatusEnum) => {
    updateOffer({ variables: { input: { id, status } } });
  };

  //Deleting data
  const [removeOffer, { loading: loadingRemove }] = useRemoveOfferMutation({
    update: (cache, { data }) => {
      if (data?.removeOffer?.success) {
        cache.modify({
          fields: {
            offers: (existingRef: any, { readField }) => {
              return existingRef.filter(
                (record: any) => readField("id", record) !== data.removeOffer.id
              );
            },
          },
        });
      }
    },
  });

  const onRemoveOffer = (id: number) => {
    removeOffer({ variables: { id } });
  };

  return (
    <>
      {contextHolder}
      <h3 style={{ fontSize: 20 }}>{t("offers", { ns: "index" })}</h3>
      <AppListBar
        onAdd={() => navigate("/admin/offers/add")}
        active={active}
        onSearch={setSearch}
        setActive={setActive}
        statistics={statistics}
      />
      <AppSpiner loading={loading || loadingUpdate || loadingRemove} />
      <Flex vertical gap="middle">
        {offers.map((d, index) => (
          <AppAlert
            key={index}
            icon={<StarOutlined />}
            title={d.title}
            details={d.description}
            status={d.status}
            onUpdate={() => navigate(`/admin/offers/${d.id}`)}
            onArchive={(status) => onArchive(d.id, status)}
            onDelete={() => onRemoveOffer(d.id)}
          />
        ))}
      </Flex>
    </>
  );
};
