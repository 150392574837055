import { useNavigate, useParams } from "react-router-dom";
import { AppFormBar } from "../../components/common/AppFormBar";
import { Form } from "antd";
import "./CooperationAddPage.css";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";
import { OfferForm } from "../../components/offer/OfferForm";
import { CreateOfferInput, StatusEnum, useOfferQuery } from "../../graphql";

export const OfferUpdatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  /***Input */

  /***Input */

  const [form] = Form.useForm<CreateOfferInput>();
  const [status, setStatus] = useState(StatusEnum.Published);

  const { loading, data: offerData } = useOfferQuery({
    variables: {
      id: Number(id),
    },
    skip: !id,
    onError(error) {
      console.log("FIND ERROR", error);
      navigate("/admin/offers");
    },
  });

  const submit = (status: StatusEnum) => {
    setStatus(status);
    form.submit(); // Trigger errors
  };

  return (
    <>
      <AppFormBar
        onCancel={() => navigate("/admin/offers")}
        title={
          offerData?.offer?.id
            ? `[${offerData?.offer?.title}]`
            : "Modifier l'appel à la candidature"
        }
        onValidate={submit}
      />

      <OfferForm form={form} offer={offerData?.offer} status={status} />
    </>
  );
};
