import { useNavigate, useParams } from "react-router-dom";
import { useProjectQuery } from "../../graphql";
import { CommonDetails } from "../../components/common/CommonDetails";

const PublicProject = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, data } = useProjectQuery({
    variables: {
      id: Number(id),
    },
    skip: !id,
    onError(error) {
      console.log("FIND ERROR", error);
      navigate("/projects");
    },
  });

  return <CommonDetails to="/projects" loading={loading} {...data?.project} />;
};

export default PublicProject;
