import { Button, Col, Form, Input, Row } from "antd";
import {
  CreateDictionaryInput,
  DictionaryDataFragment,
  DictionaryTypeEnum,
  useCreateDictionaryMutation,
  useUpdateDictionaryMutation,
} from "../../graphql";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { NotificationInstance } from "antd/es/notification/interface";
import { useEffect, useMemo } from "react";
import { SizeType } from "antd/es/config-provider/SizeContext";

type Props = {
  dictionary?: DictionaryDataFragment | null | undefined;
  setOpen: (open: boolean) => void;
  api: NotificationInstance;
  type: DictionaryTypeEnum;
  title: string;
  bordered?: boolean;
  parentId?: number;
};

export const SettingsOtherForm = ({
  dictionary,
  setOpen,
  api,
  type,
  title,
  bordered,
  parentId,
}: Props) => {
  const [form] = Form.useForm<CreateDictionaryInput>();

  useEffect(() => {
    form.setFieldsValue({
      value: dictionary?.value || "",
    });
  }, [dictionary]);

  const isUpdate = !!dictionary;

  const [createDictionary, { loading: loadingCreation }] =
    useCreateDictionaryMutation({
      onCompleted(data) {
        setOpen(false);
        if (data?.createDictionary) {
          api.success({
            message: `Notification`,
            description: "Ajout avec succès !",
          });
          form.resetFields();
        } else {
          api.error({
            message: `Erreur`,
            description: `Echec d'ajout`,
          });
        }
      },
      onError(error) {
        const exists = `${error?.message}`.includes("value_by_type");
        api.error({
          message: `Erreur`,
          description: exists
            ? "La valeur existe déjà."
            : `Erreur : ${error?.message}`,
        });
      },
      update: (cache, { data }) => {
        if (data?.createDictionary) {
          cache.modify({
            fields: {
              dictionaries(existing: any, { toReference }) {
                return [toReference(data.createDictionary), ...existing];
              },
            },
          });
        }
      },
    });

  const [updateDictionary, { loading: updateLoading }] =
    useUpdateDictionaryMutation({
      onCompleted() {
        api.success({
          message: `Notification`,
          description: "Modification avec succès !",
        });
        setOpen(false);
        form.resetFields();
      },
      onError(error) {
        const exists = `${error?.message}`.includes("value_by_type");
        api.error({
          message: `Erreur`,
          description: exists
            ? "La valeur existe déjà."
            : `Erreur : ${error?.message}`,
        });
      },
    });

  const onFinish = (input: CreateDictionaryInput) => {
    input.value = input.value.trim();
    if (isUpdate) {
      updateDictionary({
        variables: { input: { ...input, type, id: dictionary.id } },
      });
    } else
      createDictionary({
        variables: {
          input: {
            ...input,
            type,
            parentId,
          },
        },
      });
  };

  const formProps = useMemo(() => {
    if (bordered) return { height: 38, class: "", size: "small" };
    return {
      class: `cooperation-form ${!isUpdate && "establishment-form"}`,
      size: "large",
      height: 48,
    };
  }, [isUpdate, bordered]);

  return (
    <Form
      className={formProps.class}
      form={form}
      autoComplete="off"
      onFinish={onFinish}
    >
      <Row align="middle" gutter={15}>
        <Col xs={24} md={isUpdate ? 24 : 20}>
          <Form.Item
            rules={[
              { required: true, message: "Entrer le libellé" },
              { min: 3, message: "Libellé trop court" },
            ]}
            name="value"
            style={{ width: "100%!important" }}
          >
            <Input size="large" placeholder="Entrer le nom ..." type="text" />
          </Form.Item>
        </Col>
        <Col xs={24} md={isUpdate ? 24 : 4}>
          <Form.Item>
            {!!dictionary ? (
              <Button
                style={{ height: formProps.height, width: "100%" }}
                icon={<SaveOutlined />}
                type="primary"
                size={formProps.size as SizeType}
                htmlType="submit"
                loading={loadingCreation || updateLoading}
              >
                {t("save", { ns: "index" })}
              </Button>
            ) : (
              <Button
                style={{ height: formProps.height, width: "100%" }}
                icon={<PlusOutlined />}
                type="primary"
                size={formProps.size as SizeType}
                htmlType="submit"
                loading={loadingCreation || updateLoading}
              >
                {t("add", { ns: "index" })}
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
