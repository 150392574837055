import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../graphql";

export const useLogout = () => {
  const navigate = useNavigate();
  const [logout, { loading: logoutLoading }] = useLogoutMutation({
    onCompleted: (data) => {
      if (data?.logout) {
        navigate("/auth");
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return { logout, logoutLoading };
};
