import {
  MailFilled,
  BellFilled,
  UserOutlined,
  DownOutlined,
  MenuOutlined,
  LogoutOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  theme,
  Flex,
  Divider,
  Space,
  Avatar,
  Popover,
  Menu,
  Button,
  List,
  Spin,
} from "antd";
import { MenuItemType, ItemType } from "antd/es/menu/hooks/useItems";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWhoAmIQuery } from "../graphql";
import { useLogout } from "../hooks/useLogout";

type Props = {
  items?: ItemType<MenuItemType>[];
  isDesktop?: boolean;
};

export const AdminBarActions = ({ items, isDesktop }: Props) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation("user");

  const { pathname } = useLocation();

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const handleSelect = (key: string) => {
    setOpen(false);
    navigate(`/admin/${key}`);
  };
  const {
    token: { colorBgContainerDisabled, colorPrimary },
  } = theme.useToken();

  const iconStyle = {
    color: "#666666",
    strokeWidth: 100,
    marginLeft: 8,
    stroke: "#666666",
  };

  /*** avatar profile*/
  const [openAvatar, setOpenAvatar] = useState(false);

  //Get current user
  const { data: dataMe } = useWhoAmIQuery({
    onCompleted(data) {},
    onError(error) {
      console.log(error);
    },
  });

  const avatarMenu = [
    {
      key: "profile",
      icon: <UserOutlined />,
      label: t("profile"),
      description: dataMe?.whoAmI?.identify,
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: t("logout"),
    },
  ];

  const openAvatarChange = (newOpen: boolean) => {
    setOpenAvatar(newOpen);
  };

  const { logout, logoutLoading } = useLogout();

  const onMenuAvatarClick = (key: string) => {
    setOpenAvatar(false);
    switch (key) {
      case "logout":
        logout();
        break;
      case "profile":
        break;
    }
  };

  return (
    <Flex gap="middle" justify="space-between" align="center">
      <MailFilled style={{ ...iconStyle, fontSize: "20px" }} />
      <BellFilled style={{ ...iconStyle, fontSize: "20px" }} />
      <Divider
        style={{
          height: 42,
          width: 2,
          background: colorBgContainerDisabled,
        }}
        type="vertical"
      />
      <Space size={2} wrap>
        <Popover
          overlayStyle={{ width: "auto" }}
          content={
            <List
              itemLayout="horizontal"
              dataSource={avatarMenu}
              renderItem={(it: any, index) => (
                <List.Item
                  style={{
                    cursor: "pointer",
                  }}
                  key={index}
                  onClick={() => onMenuAvatarClick(it.key)}
                >
                  <Space>
                    <Avatar
                      style={{ background: colorPrimary }}
                      icon={it.icon}
                    />
                    <div>
                      <div>{it.label}</div>
                      <div style={{ color: colorBgContainerDisabled }}>
                        {it?.description}
                      </div>
                    </div>
                  </Space>
                </List.Item>
              )}
            />
          }
          arrow={true}
          trigger="click"
          open={openAvatar}
          placement="bottom"
          onOpenChange={openAvatarChange}
        >
          <span style={{ marginRight: 20 }}>
            <Avatar size={48} icon={<UserOutlined />} />

            {logoutLoading ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 15 }} spin />}
              />
            ) : (
              <DownOutlined
                style={{
                  ...iconStyle,
                  color: colorBgContainerDisabled,
                  stroke: colorBgContainerDisabled,
                }}
              />
            )}
          </span>
        </Popover>

        {!isDesktop && items && (
          <Popover
            content={
              <Menu
                mode="inline"
                defaultSelectedKeys={[pathname.split("/")[2]]}
                onSelect={({ key }) => handleSelect(key)}
                items={items}
              />
            }
            title="Menu"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button type="text" icon={<MenuOutlined />} />
          </Popover>
        )}
      </Space>
    </Flex>
  );
};
