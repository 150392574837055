import { Drawer, Flex, Table, Typography, notification } from "antd";
import { SettingsOtherForm } from "../../../components/settings/SettingsOtherForm";
import { useEffect, useState } from "react";
import {
  DictionaryDataFragment,
  DictionaryTypeEnum,
  useDictionariesLazyQuery,
} from "../../../graphql";
import { useSettingsOtherColumn } from "./useSettingsOtherColumn";
import { useLocation, useParams } from "react-router-dom";
import { SettingsMenu } from "../../../types";
import { SettingsDescription } from "../../../components/settings/SettingsDescription";

export const SettingsOtherPage = () => {
  const [open, setOpen] = useState(false);
  const params = useParams();
  const [dictionaryEnum, setDictionaryEnum] = useState({
    label: "Types de coopération",
    value: DictionaryTypeEnum.CooperationType,
  });
  const [reloadData, { loading, data }] = useDictionariesLazyQuery();
  const [api, contextHolder] = notification.useNotification();
  const [dictionary, setDictionary] = useState<
    DictionaryDataFragment | undefined
  >(undefined);

  const openDrawer = (dic?: DictionaryDataFragment) => {
    setDictionary(dic);
    setOpen(true);
  };

  const { columns } = useSettingsOtherColumn({
    openDrawer,
    api,
  });

  useEffect(() => {
    console.log(params["type"]);
    switch (params["type"] as SettingsMenu) {
      case SettingsMenu.cooperation:
        setDictionaryEnum({
          label: "Types de coopération",
          value: DictionaryTypeEnum.CooperationType,
        });
        reloadData({
          variables: { types: [DictionaryTypeEnum.CooperationType] },
        });
        break;

      case SettingsMenu.domain:
        setDictionaryEnum({
          label: "Domaine d`expertise",
          value: DictionaryTypeEnum.AgreementDomain,
        });
        reloadData({
          variables: { types: [DictionaryTypeEnum.AgreementDomain] },
        });
        break;

      case SettingsMenu.discipline:
        setDictionaryEnum({
          label: "Disciplines",
          value: DictionaryTypeEnum.CooperationDiscipline,
        });
        reloadData({
          variables: { types: [DictionaryTypeEnum.CooperationDiscipline] },
        });
        break;

      case SettingsMenu.project:
        setDictionaryEnum({
          label: "Types de projet",
          value: DictionaryTypeEnum.ProjectType,
        });
        reloadData({
          variables: { types: [DictionaryTypeEnum.ProjectType] },
        });
        break;

      case SettingsMenu.scholarship:
        setDictionaryEnum({
          label: "Types de bourse",
          value: DictionaryTypeEnum.Scholarship,
        });
        reloadData({
          variables: { types: [DictionaryTypeEnum.Scholarship] },
        });
        break;

      case SettingsMenu.agreement:
        setDictionaryEnum({
          label: "Types d'accord",
          value: DictionaryTypeEnum.AgreementType,
        });
        reloadData({
          variables: { types: [DictionaryTypeEnum.AgreementType] },
        });
        break;

      case SettingsMenu.level:
        setDictionaryEnum({
          label: "Niveaux",
          value: DictionaryTypeEnum.AgreementLevel,
        });
        reloadData({
          variables: { types: [DictionaryTypeEnum.AgreementLevel] },
        });
        break;

      default:
        setDictionaryEnum({
          label: "Paramètre de coopération",
          value: DictionaryTypeEnum.CooperationType,
        });
        break;
    }
  }, [params, reloadData]);

  return (
    <>
      {contextHolder}
      <Flex justify="space-between" align="center" style={{ marginBottom: 15 }}>
        <Typography.Title style={{ fontSize: 25, color: "var(--black)" }}>
          {dictionaryEnum.label}
        </Typography.Title>
        <SettingsDescription api={api} type={params["type"] as any} />
      </Flex>
      <SettingsOtherForm
        type={dictionaryEnum.value}
        title={dictionaryEnum.label.toLowerCase()}
        setOpen={setOpen}
        api={api}
      />
      <Table
        loading={loading}
        style={{ marginTop: 10 }}
        showHeader={false}
        rowKey="id"
        //rowSelection={rowSelection}
        columns={columns}
        dataSource={data?.dictionaries?.filter(
          (dic) => !dic.parentId && dic.type === dictionaryEnum.value
        )}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Typography style={{ padding: "0 12px 12px 12px" }}>
                Nouvelle sous-bourse
              </Typography>
              <SettingsOtherForm
                type={dictionaryEnum.value}
                title="Sous-bourse"
                setOpen={setOpen}
                api={api}
                bordered
                parentId={record.id}
              />
              <Table
                showHeader={false}
                rowKey="id"
                columns={columns}
                dataSource={data?.dictionaries?.filter(
                  (dic) => dic.parentId === record.id
                )}
              />
            </>
          ),
          rowExpandable: (record) =>
            !record.parentId && record.type === DictionaryTypeEnum.Scholarship,
        }}
      />
      <Drawer
        placement="right"
        width="auto"
        closable={true}
        onClose={() => setOpen(false)}
        title={
          <Typography.Title style={{ fontSize: 25 }}>
            Mettre à jour
          </Typography.Title>
        }
        open={open}
      >
        <SettingsOtherForm
          title={dictionaryEnum.label.toLowerCase()}
          setOpen={setOpen}
          type={dictionaryEnum.value}
          api={api}
          dictionary={dictionary}
        />
      </Drawer>
    </>
  );
};
