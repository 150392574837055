import { PlusOutlined } from "@ant-design/icons";
import { Flex, Tabs, Badge, Button, theme } from "antd";
import Search from "antd/es/input/Search";
import { useTranslation } from "react-i18next";
import "./AppListBar.css";
import { StatusEnum } from "../../graphql";
import { useMemo } from "react";

type Props = {
  active: string;
  setActive: (val: string) => void;
  onAdd?: () => void;
  onSearch?: (value: string) => void;
  statistics?: Record<StatusEnum, number>;
};

export const AppListBar = ({
  active,
  setActive,
  onAdd,
  onSearch,
  statistics,
}: Props) => {
  const { t } = useTranslation(["cooperation", "index"]);
  const items = useMemo(() => {
    return [
      {
        label: t("published"),
        count: statistics?.PUBLISHED || 0,
      },
      {
        label: t("achived"),
        count: statistics?.ARCHIVED || 0,
      },
      {
        label: t("draft"),
        count: statistics?.DRAFT || 0,
      },
    ];
  }, [statistics]);

  const {
    token: { colorPrimaryBg, colorTextDisabled },
  } = theme.useToken();

  const getColor = (index: number) => {
    return active === `${index}` ? colorPrimaryBg : colorTextDisabled;
  };
  return (
    <Flex justify="space-between" wrap="wrap">
      <Tabs
        defaultActiveKey={active}
        onChange={setActive}
        items={items.map(({ label, count }, index) => {
          return {
            label: (
              <span
                style={{
                  color: getColor(index),
                }}
              >
                {label}
                <Badge
                  color={getColor(index)}
                  status="success"
                  style={{
                    marginLeft: 10,
                    color: "white",
                    borderColor: "transparent",
                  }}
                  count={count}
                  showZero
                />
              </span>
            ),
            key: `${index}`,
          };
        })}
      />
      <Flex gap="small">
        <Search
          placeholder="Rechercher ..."
          className="app-list-bar-search"
          allowClear
          enterButton
          onSearch={(value) => onSearch && onSearch(value)}
          size="large"
        />
        <Button
          onClick={onAdd}
          icon={<PlusOutlined />}
          type="primary"
          size="large"
        >
          {t("add", { ns: "index" })}
        </Button>
      </Flex>
    </Flex>
  );
};
