import {
  CheckOutlined,
  CloseCircleFilled,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, Space } from "antd";
import { useTranslation } from "react-i18next";
import { StatusEnum } from "../../graphql";

type Props = {
  title: string;
  onCancel?: () => void;
  onValidate?: (status: StatusEnum) => void;
  disabled?: boolean;
};

export const AppFormBar = ({
  title,
  onCancel,
  disabled,
  onValidate,
}: Props) => {
  const { t } = useTranslation(["cooperation", "index"]);
  return (
    <Card>
      <Flex wrap="wrap" justify="space-between">
        <h3>{title}</h3>
        <Space size="middle" wrap>
          <Button
            onClick={() => onValidate && onValidate(StatusEnum.Draft)}
            disabled={disabled}
            size="large"
            type="text"
          >
            {t("draft", { ns: "index" })} <SaveOutlined />
          </Button>
          <Button disabled={disabled} onClick={onCancel} size="large">
            {t("cancel", { ns: "index" })} {<CloseCircleFilled />}
          </Button>
          <Button
            onClick={() => onValidate && onValidate(StatusEnum.Published)}
            disabled={disabled}
            size="large"
            type="primary"
          >
            {t("publish", { ns: "index" })} {<CheckOutlined />}
          </Button>
        </Space>
      </Flex>
    </Card>
  );
};
