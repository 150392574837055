import { AgreementStatus } from "../graphql";
import { useTranslation } from "react-i18next";

export const useEnumTranslation = () => {
  const { t } = useTranslation(["cooperation", "index"]);

  const AgreementStatusData = [
    AgreementStatus.EnVigueur,
    AgreementStatus.EnVoieDeSignature,
    AgreementStatus.Inactif,
  ].map((label) => ({ value: label as any, label: t(label) }));

  return { AgreementStatusData };
};
