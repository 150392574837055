import { Flex } from "antd";
import { Header } from "antd/es/layout/layout";
import "./AppHeader.css";
import { useTranslation } from "react-i18next";
import { useResponsive } from "../hooks/useResponsive";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

export const AppHeader = ({ children }: Props) => {
  const { t } = useTranslation("index");
  const { isDesktop } = useResponsive();

  return (
    <Header className="main-header">
      <Flex wrap="wrap" align="center">
        <img alt="" src="/logo-imf.png" />
        {isDesktop && (
          <span>
            <h1>{t("university_cooperation")}</h1>
            <p>{t("ambassador_france")}</p>
          </span>
        )}
      </Flex>
      {children}
    </Header>
  );
};
