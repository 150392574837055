import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppAlert } from "../../components/common/AppAlert";
import { AppListBar } from "../../components/common/AppListBar";
import { Flex, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { ShoppingOutlined } from "@ant-design/icons";
import AppSpiner from "../../components/common/AppSpiner";
import {
  StatusEnum,
  useProjectUpdateMutation,
  useProjectsQuery,
  useRemoveProjectMutation,
} from "../../graphql";
import { statusBar } from "../../constants/status-bar";

export const ProjectPage = () => {
  const { t } = useTranslation(["project", "index"]);
  const [active, setActive] = useState("0");

  const [api, contextHolder] = notification.useNotification();

  const [search, setSearch] = useState("");

  const { loading, data } = useProjectsQuery({});

  const navigate = useNavigate();

  const projects = useMemo(() => {
    return (data?.projects || []).filter((c) => {
      const statusOk = c.status === statusBar[active];
      const titleOk = c.title.toLowerCase().includes(search.toLowerCase());
      return statusOk && titleOk;
    });
  }, [data, active, search]);

  const statistics = useMemo(() => {
    return (data?.projects || []).reduce(
      (acc, cur) => {
        acc[cur.status] += 1;
        return acc;
      },
      {
        [StatusEnum.Published]: 0,
        [StatusEnum.Archived]: 0,
        [StatusEnum.Draft]: 0,
      }
    );
  }, [data]);

  //Update data
  const [updateProject, { loading: loadingUpdate }] = useProjectUpdateMutation({
    onError: (error) => {
      api.error({
        message: "Modification ddu projet",
        description: `Erreur : ${error?.message}`,
      });
    },
  });

  const onArchive = (id: number, status: StatusEnum) => {
    updateProject({ variables: { input: { id, status } } });
  };

  //Deleting data
  const [removeProject, { loading: loadingRemove }] = useRemoveProjectMutation({
    update: (cache, { data }) => {
      if (data?.removeProject?.success) {
        cache.modify({
          fields: {
            projects: (existingRef: any, { readField }) => {
              return existingRef.filter(
                (record: any) =>
                  readField("id", record) !== data.removeProject.id
              );
            },
          },
        });
      }
    },
  });

  const onRemoveProject = (id: number) => {
    removeProject({ variables: { id } });
  };

  return (
    <>
      {contextHolder}
      <h3 style={{ fontSize: 20 }}>{t("list_projects")}</h3>
      <AppListBar
        onAdd={() => navigate("/admin/projects/add")}
        active={active}
        setActive={setActive}
        onSearch={setSearch}
        statistics={statistics}
      />
      <AppSpiner loading={loading || loadingUpdate || loadingRemove} />
      <Flex vertical gap="middle">
        {projects.map((d, index) => (
          <AppAlert
            key={index}
            icon={<ShoppingOutlined />}
            title={d.title}
            details={d.description}
            status={d.status}
            onUpdate={() => navigate(`/admin/projects/${d.id}`)}
            onArchive={(status) => onArchive(d.id, status)}
            onDelete={() => onRemoveProject(d.id)}
          />
        ))}
      </Flex>
    </>
  );
};
