import { useNavigate, useParams } from "react-router-dom";
import { AppFormBar } from "../../components/common/AppFormBar";
import { Form } from "antd";
import "./CooperationAddPage.css";
import {
  CooperationCreateInput,
  CooperationDataFragment,
  StatusEnum,
  useCooperationQuery,
} from "../../graphql";
import { useState } from "react";
import { CooperationForm } from "../../components/cooperation/CooperationForm";
import { cloneDeep } from "lodash";

export const CooperationUpdatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  /***Input */

  /***Create cooperation */
  const [createdData, setCreatedData] = useState<
    CooperationDataFragment | undefined | null
  >(undefined);

  const [form] = Form.useForm<CooperationCreateInput>();
  const [status, setStatus] = useState(StatusEnum.Published);

  const { loading } = useCooperationQuery({
    variables: {
      id: Number(id),
    },
    skip: !id,
    onCompleted(data) {
      setCreatedData(cloneDeep(data?.cooperation));
    },
    onError(error) {
      console.log("FIND ERROR", error);
      navigate("/admin/cooperations");
    },
  });

  const submit = (status: StatusEnum) => {
    setStatus(status);
    form.submit(); // Trigger errors
  };

  return (
    <>
      <AppFormBar
        onCancel={() => navigate("/admin/cooperations")}
        title={`Mise à jour [${createdData?.title}]`}
        disabled={loading}
        onValidate={submit}
      />

      <CooperationForm
        cooperation={createdData}
        status={status}
        onSaved={setCreatedData}
        form={form}
        submit={submit}
      />
    </>
  );
};
