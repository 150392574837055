import { ApolloLink, createHttpLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

const uri = `${process.env.REACT_APP_API_BASE_URL}/graphql`;

export const uploadLink = ApolloLink.split(
  // Allows apollo to know wich url to use on uploading
  (operation) => operation.getContext().hasUpload,
  createUploadLink({
    uri,
    credentials: "include",
    headers: {
      "Content-Type": "multipart/form-data",
      "Apollo-Require-Preflight": "true",
    },
  }) as any
);

export const httpLink = createHttpLink({
  uri,
  credentials: "include",
});
