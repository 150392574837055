import { useNavigate } from "react-router-dom";
import { AppFormBar } from "../../../components/common/AppFormBar";
import { Form } from "antd";
import "../CooperationAddPage.css";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";
import { BourseForm } from "../../../components/bourse/BourseForm";
import { CreateBourseInput, StatusEnum } from "../../../graphql";

export const BourseAddPage = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm<CreateBourseInput>();
  const [status, setStatus] = useState(StatusEnum.Published);

  const submit = (status: StatusEnum) => {
    setStatus(status);
    form.submit(); // Trigger errors
  };

  return (
    <>
      <AppFormBar
        onCancel={() => navigate("/admin/scholarships")}
        title="Ajouter une bourse"
        onValidate={submit}
      />

      <BourseForm form={form} bourse={undefined} status={status} />
    </>
  );
};
