import { ApolloClient, from, InMemoryCache } from "@apollo/client";

import { errorLink, httpLink, uploadLink } from "./links";

// Use in memory cache to easy state management
export const apolloCache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: from([errorLink, uploadLink, httpLink]), // concat all links
  cache: apolloCache,
});
