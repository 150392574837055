import { Modal, notification } from "antd";
import { cloneDeep } from "lodash";
import {
  CooperationDataFragment,
  StatusEnum,
  useCooperationCreateMutation,
  useCooperationUpdateMutation,
  useRemoveCooperationMutation,
} from "../../graphql";
import { useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

type Props = {
  onSaved?: (data: CooperationDataFragment | null | undefined) => void;
  status?: StatusEnum;
};
export const useCooperation = ({ onSaved, status }: Props) => {
  const { t } = useTranslation("index");
  const [_status, _setStatus] = useState<StatusEnum>(
    status || StatusEnum.Published
  );
  const getMessage = () => {
    switch (_status) {
      case StatusEnum.Published:
        return "publiée";
      case StatusEnum.Archived:
        return "achivée";
      case StatusEnum.Draft:
        return "mise en brouillon";
    }
  };
  const [api, contextHolder] = notification.useNotification();
  const [modal, contextHolderModal] = Modal.useModal();

  const [createCooperation, { loading: loadingCreation }] =
    useCooperationCreateMutation({
      onCompleted: (data) => {
        if (onSaved) onSaved(cloneDeep(data?.cooperationCreate));
        api.success({
          message: "Nouvelle coopération",
          description: `Coopération créée et ${getMessage()} avec succès !`,
        });
      },
      onError: (error) => {
        api.error({
          message: "Modification de coopération",
          description: `Erreur : ${error?.message}`,
        });
      },
      update: (cache, { data }) => {
        if (data?.cooperationCreate) {
          cache.modify({
            fields: {
              cooperations: (existingRef: any, { toReference }) => {
                return [toReference(data.cooperationCreate), ...existingRef];
              },
            },
          });
        }
      },
    });

  const [updateCooperation, { loading: loadingUpdate }] =
    useCooperationUpdateMutation({
      onCompleted: (data) => {
        if (onSaved) onSaved(cloneDeep(data?.cooperationUpdate));
        api.success({
          message: "Modification de coopération",
          description: `Coopération ${getMessage()} avec succès !`,
        });
      },
      onError: (error) => {
        api.error({
          message: "Modification de coopération",
          description: `Erreur : ${error?.message}`,
        });
      },
    });

  const [removeMutation, { loading: loadingRemove }] =
    useRemoveCooperationMutation({
      onCompleted: (data) => {
        if (data?.removeCooperation) {
          api.success({
            message: "Suppression de coopération",
            description: `Coopération supprimée avec succès !`,
          });
        } else {
          api.error({
            message: "Suppression de coopération",
            description: `Une erreur est survenue lors de la suppression! Réessayez plutard`,
          });
        }
      },
      onError(err) {
        api.error({
          message: "Suppression de coopération",
          description: `Erreur : ${err?.message}`,
        });
      },
      update: (cache, { data }) => {
        if (data?.removeCooperation) {
          cache.modify({
            fields: {
              cooperations: (existingRef: any, { readField }) => {
                return existingRef.filter(
                  (record: any) =>
                    readField("id", record) !== data.removeCooperation
                );
              },
            },
          });
        }
      },
    });

  const removeCooperation = (id: number) => {
    modal.confirm({
      title: "Etes-vous sûr de vouloir supprimer ?",
      icon: <ExclamationCircleFilled />,
      content: "Cette coopération sera supprimée définitivement.",
      okText: t("yes"),
      okType: "danger",
      okButtonProps: {
        danger: true,
      },
      cancelText: t("no"),
      onOk: () => removeMutation({ variables: { id } }),
    });
  };

  const updateStatus = (id: number, status: StatusEnum) => {
    _setStatus(status);
    updateCooperation({
      variables: { input: { status, id } },
    });
  };

  return {
    createCooperation,
    loadingCreation,
    updateCooperation,
    loadingUpdate,
    contextHolder,
    contextHolderModal,
    removeCooperation,
    loadingRemove,
    updateStatus,
  };
};
