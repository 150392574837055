import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Table,
  Typography,
  notification,
} from "antd";
import {
  DictionaryTypeEnum,
  useDictionariesQuery,
  useEstablishmentsQuery,
} from "../../../graphql";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import {
  EstablishmentRow,
  useEstablishmentsColumn,
} from "./useEstablishmentColumn";
import { EstablishmentForm } from "../../../components/settings/EstablishmentForm";

type FormSearch = {
  region: string[];
  cities: number[];
  filter: string;
};

export const EstablishmentPage = () => {
  const { t } = useTranslation(["index"]);
  const [form] = Form.useForm<FormSearch>();
  const [establishment, setEstablishment] = useState<
    EstablishmentRow | undefined
  >();
  const [open, setOpen] = useState(false);

  const openDrawer = (esta?: EstablishmentRow) => {
    setEstablishment(esta);
    setOpen(true);
  };

  const [api, contextHolder] = notification.useNotification();

  const defaultValues = {
    region: [] as string[],
    cities: [] as number[],
    filter: "",
  };

  const region = Form.useWatch("region", form);
  const cities = Form.useWatch("cities", form);
  const filter = Form.useWatch("filter", form);

  useEffect(() => {
    form.setFieldsValue({ ...defaultValues });
  }, []);

  const { data: establishmentsData, loading: establishmentsLoading } =
    useEstablishmentsQuery();

  const { data: dictionaryData, loading: dictionalyLoading } =
    useDictionariesQuery({
      variables: {
        types: [
          DictionaryTypeEnum.MadagascarCity,
          DictionaryTypeEnum.FranceCity,
        ],
      },
    });

  const countries = [
    DictionaryTypeEnum.MadagascarRegion,
    DictionaryTypeEnum.FranceRegion,
  ].map((value) => {
    const region = value.split("_")[0].toLowerCase();
    return {
      value: region,
      label: t(region),
    };
  });

  const getFormValues = () => {
    const values = form.getFieldsValue();
    if (isEmpty(values)) return defaultValues;
    return values;
  };

  const establishments = useMemo(() => {
    const data = (establishmentsData?.establishments || []).reduce(
      (acc, cur) => {
        const { children, ...parent } = cur;
        const ch: EstablishmentRow[] = cur.children.map((ch) => ({
          ...ch,
          parent,
        }));
        return acc.concat(ch);
      },
      [] as EstablishmentRow[]
    );

    const search = getFormValues();

    if (!search.cities.length && !search.region.length && !search.filter) {
      return data;
    }

    return data.filter((esta) => {
      const okName = (esta.name || esta?.parent?.name || "")
        .toLowerCase()
        .includes(search.filter.toLowerCase());

      const region = (esta.town?.type || "").toLowerCase().split("_")[0];

      let okRegion = true;
      let okCity = true;
      if (search.region.length) okRegion = search.region.includes(region);

      if (search.cities.length) {
        okCity = search.cities.includes(esta.town?.id || 0);
      }

      return okName && okRegion && okCity;
    });
  }, [region, cities, filter, establishmentsData]);

  const citiesOptions = useMemo(() => {
    let data = dictionaryData?.dictionaries || [];
    const search = getFormValues();
    if (search.region.length) {
      data = data.filter((city) => {
        const region = city.type.split("_")[0].toLowerCase();
        return search.region.includes(region);
      });
    }
    return data.map((city) => ({ value: city.id, label: city.value }));
  }, [dictionaryData, region]);

  const { columns, rowSelection } = useEstablishmentsColumn({
    openDrawer,
    api,
  });

  return (
    <>
      {contextHolder}
      <Flex justify="space-between" align="center" style={{ marginBottom: 15 }}>
        <Typography.Title style={{ fontSize: 25, color: "var(--black)" }}>
          Université
        </Typography.Title>
        <Button
          onClick={() => openDrawer()}
          icon={<PlusOutlined />}
          type="primary"
          size="large"
        >
          {t("add", { ns: "index" })}
        </Button>
      </Flex>

      <Form form={form} layout="vertical" autoComplete="off">
        <Row gutter={[15, 15]} className="cooperation-form establishment-form">
          <Col sm={24} md={8}>
            <Form.Item name="region">
              <Select
                options={countries}
                mode="multiple"
                maxTagCount="responsive"
                onChange={() => form.submit()}
                allowClear
                size="large"
                placeholder="Séléctionner des pays ...."
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item name="cities">
              <Select
                loading={dictionalyLoading}
                options={citiesOptions}
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                onChange={() => form.submit()}
                size="large"
                placeholder="Séléctionner des villes ...."
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item name="filter">
              <Input
                size="large"
                placeholder="Entrer le nom d'université ..."
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        loading={establishmentsLoading}
        rowSelection={rowSelection}
        expandable={{
          showExpandColumn: false,
          expandRowByClick: false,
        }}
        style={{ marginTop: 10 }}
        rowKey="id"
        columns={columns}
        dataSource={establishments}
      />
      <Drawer
        placement="right"
        width="auto"
        closable={true}
        onClose={() => {
          setOpen(false);
          setEstablishment(undefined);
        }}
        open={open}
      >
        <EstablishmentForm
          api={api}
          establishment={establishment}
          establishments={(establishmentsData?.establishments || []).map(
            ({ name, id }) => ({
              value: name,
              id,
            })
          )}
          setOpen={setOpen}
          countries={countries}
        />
      </Drawer>
    </>
  );
};
