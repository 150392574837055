import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Typography,
  Flex,
  Space,
  Button,
} from "antd";
import {
  CreateUserInput,
  RoleEnum,
  UserDataFragment,
  UserStatusEnum,
  useCreateUserMutation,
  useUpdateUserMutation,
} from "../../graphql";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotificationInstance } from "antd/es/notification/interface";

type Props = {
  user: UserDataFragment | null | undefined;
  setOpen: (open: boolean) => void;
  api: NotificationInstance;
};

export const UserForm = ({ user, setOpen, api }: Props) => {
  const { t } = useTranslation("user");
  const [form] = Form.useForm<CreateUserInput>();

  useEffect(() => {
    form.setFieldsValue({
      name: user?.name || "",
      identify: user?.identify || "",
      role: user?.role || RoleEnum.Manager,
      status: user?.status || UserStatusEnum.Active,
      password: "",
    });
  }, [user]);

  const [createUser, { loading: loadingCreation }] = useCreateUserMutation({
    onCompleted(data) {
      setOpen(false);
      if (data?.createUser) {
        api.success({
          message: "Nouvel utilisateur",
          description: "L'utilisateur ajouté avec succès !",
        });
      } else {
        api.error({
          message: "Ajout d'utilisateur",
          description: `Echec d'ajout d'utilisateur`,
        });
      }
    },
    onError(error) {
      api.error({
        message: "Ajout d'utilisateur",
        description: `Erreur : ${error?.message}`,
      });
    },
    update: (cache, { data }) => {
      if (data?.createUser) {
        cache.modify({
          fields: {
            users(existing: any, { toReference }) {
              return [toReference(data.createUser), ...existing];
            },
          },
        });
      }
    },
  });

  const [updateUser, { loading: updateLoading }] = useUpdateUserMutation({
    onCompleted() {
      api.success({
        message: "Modification d'utilisateur",
        description: "Utilisateur modifié avec succès !",
      });
      setOpen(false);
    },
    onError(error) {
      api.error({
        message: "Modification d'utilisateur",
        description: `Erreur : ${error?.message}`,
      });
    },
  });

  const onFinish = (input: CreateUserInput) => {
    if (user) {
      updateUser({ variables: { input: { ...input, id: user.id } } });
    } else createUser({ variables: { input } });
  };

  const rolesOptions = [RoleEnum.Admin, RoleEnum.Manager].map((value) => ({
    label: value,
    value,
  }));
  const statusOptions = [UserStatusEnum.Active, UserStatusEnum.Inactive].map(
    (value) => ({
      label: value,
      value,
    })
  );

  return (
    <>
      <Flex
        style={{ paddingTop: 50, paddingBottom: 50 }}
        justify="space-between"
        gap={10}
      >
        <Typography.Title style={{ fontSize: 20 }}>
          Ajout utilisateur
        </Typography.Title>
        <Space>
          <Button
            disabled={loadingCreation || updateLoading}
            size="large"
            type="default"
          >
            Annuler
          </Button>
          <Button
            onClick={() => form.submit()}
            disabled={loadingCreation || updateLoading}
            size="large"
            type="primary"
          >
            Enregistrer
          </Button>
        </Space>
      </Flex>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        className="cooperation-form"
      >
        <Row gutter={10}>
          <Col sm={24} md={12}>
            <Form.Item
              rules={[{ required: true, message: "Nom requis" }]}
              name="name"
              label={t("name")}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item
              rules={[{ required: true, message: "Identifiant requis" }]}
              name="identify"
              label={t("identify")}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col sm={24} md={12}>
            <Form.Item
              rules={[{ required: true, message: "Role requis" }]}
              name="role"
              label={t("role")}
            >
              <Select
                showSearch
                allowClear
                size="large"
                placeholder="Select ..."
                optionFilterProp="children"
                options={rolesOptions}
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item
              rules={[{ required: true, message: "Statut" }]}
              name="status"
              label={t("status")}
            >
              <Select
                showSearch
                allowClear
                size="large"
                placeholder="Select ..."
                optionFilterProp="children"
                options={statusOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          rules={[{ required: !user, message: "Mot de passe requis" }]}
          name="password"
          label={t("password")}
        >
          <Input size="large" type="password" />
        </Form.Item>
      </Form>
    </>
  );
};
