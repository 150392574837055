import { Form, Card, Select, Flex, Alert } from "antd";
import { AppAlert } from "../../components/common/AppAlert";
import { ShoppingOutlined } from "@ant-design/icons";
import {
  DictionaryTypeEnum,
  useDictionariesQuery,
  useProjectsQuery,
} from "../../graphql";
import { useCallback, useEffect, useMemo } from "react";
import { filTerDictionary } from "../../utils/build-options";
import AppSpiner from "../../components/common/AppSpiner";
import { sanitize } from "dompurify";

export const PublicProjects = () => {
  const [form] = Form.useForm();

  const { data: dictionaryData, loading: dictionalyLoading } =
    useDictionariesQuery({
      variables: {
        types: [
          DictionaryTypeEnum.ProjectType,
          DictionaryTypeEnum.PageProjectDesc,
        ],
      },
    });

  const getSelectOptions = useCallback(
    (type: DictionaryTypeEnum) =>
      filTerDictionary(type, dictionaryData?.dictionaries || []),
    [dictionaryData]
  );

  const { loading, data, refetch } = useProjectsQuery({
    variables: {
      input: {
        types: [],
      },
    },
  });

  useEffect(() => {
    form.setFieldValue("types", []);
  }, []);

  const refetchData = () => {
    refetch({ input: form.getFieldsValue() });
  };

  const getDescription = useMemo(() => {
    const desc = dictionaryData?.dictionaries?.find(
      (dic) => dic.type === DictionaryTypeEnum.PageProjectDesc
    )?.value;
    return sanitize(desc || "");
  }, [dictionaryData]);

  return (
    <>
      {getDescription ? (
        <Alert
          message="Les projets en France"
          description={
            <div dangerouslySetInnerHTML={{ __html: getDescription }} />
          }
          type="info"
          className="text-justity"
          banner
          style={{
            marginBottom: 20,
            background: "white",
            border: "unset",
          }}
          showIcon
        />
      ) : null}
      <Form form={form} layout="vertical" className="public-form">
        <Card>
          <Form.Item
            style={{ maxWidth: 398 }}
            name="types"
            label="Types de projets"
          >
            <Select
              showSearch
              placeholder="Séléctionner ..."
              mode="multiple"
              allowClear
              maxTagCount="responsive"
              onChange={refetchData}
              loading={dictionalyLoading}
              optionFilterProp="children"
              options={getSelectOptions(DictionaryTypeEnum.ProjectType)}
            />
          </Form.Item>
        </Card>
      </Form>

      <AppSpiner loading={loading} />

      <Flex vertical gap="middle">
        {(data?.projects || []).map((d, index) => (
          <AppAlert
            icon={<ShoppingOutlined />}
            title={d.title}
            key={index}
            details={d.description}
            to={`/projects/${d.id}`}
            onOpen={d.link ? () => window.open(d.link, "_blank") : undefined}
          />
        ))}
      </Flex>
    </>
  );
};
