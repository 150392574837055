import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { TableColumnsType, Button, TableProps, Popconfirm, Space } from "antd";
import {
  DictionaryDataFragment,
  useRemoveDictionnaryMutation,
} from "../../../graphql";
import { NotificationInstance } from "antd/es/notification/interface";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  openDrawer: (dictionary: DictionaryDataFragment) => void;
  api: NotificationInstance;
};
type TableRowSelection<T> = TableProps<T>["rowSelection"];

export const useSettingsOtherColumn = ({ openDrawer, api }: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { t } = useTranslation("index");
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DictionaryDataFragment> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [removeDictionary] = useRemoveDictionnaryMutation({
    onCompleted(data) {
      api.success({
        message: "Suppression",
        description: `L'élément "${data.removeDictionary.value}" a été supprimé`,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    },
    onError(error) {
      api.error({
        message: "Suppression",
        description: `Erreur : ${error?.message}`,
      });
    },
  });

  const columns: TableColumnsType<DictionaryDataFragment> = [
    {
      title: "",
      dataIndex: "value",
      render: (value) => `${value}`,
    },
    {
      title: "action",
      dataIndex: "id",
      width: 65,
      render: (id, record) => (
        <Space size={10}>
          <Button
            onClick={() => openDrawer(record)}
            size="small"
            icon={<EditFilled />}
          />

          <Popconfirm
            title="Attention !"
            description="Les autres éléments qui en dependent directement seront supprimés."
            onConfirm={() => removeDictionary({ variables: { id } })}
            okText={t("confirm")}
            cancelText={t("cancel")}
            okButtonProps={{ danger: true }}
          >
            <Button danger size="small" icon={<DeleteFilled />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return { columns, rowSelection, selectedRowKeys };
};
