import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Input, Row, theme } from "antd";
import Link from "antd/es/typography/Link";
import { RegExpPattern } from "../../constants/reg-exp";
import { useTranslation } from "react-i18next";
import { LoginInput, useLoginMutation } from "../../graphql";
import { localData } from "../../constants/localstorage";
import { useNavigate } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export const LoginPage = () => {
  const { t } = useTranslation("user");

  const [form] = Form.useForm<LoginInput>();

  const {
    token: { colorTextBase },
  } = theme.useToken();

  const validateEmail = (_: any, value: string) => {
    if (value && value.includes("@")) {
      if (RegExpPattern.email.test(value)) return Promise.resolve();
      else return Promise.reject(new Error("Email invalid"));
    }
    return Promise.resolve();
  };

  const navigate = useNavigate();

  const [mutate] = useLoginMutation({
    onCompleted({ login }) {
      if (login) {
        if (form.getFieldValue("remember").at(0)) {
          localStorage.setItem(
            localData.rememberMe,
            JSON.stringify({
              identify: login.identify,
              checked: true,
            })
          );
        }
        navigate("/admin");
      }
    },
    update: (cache, { data }) => {
      if (data?.login) {
        cache.modify({
          fields: {
            whoAmI(existing: any, { toReference }) {
              return {
                ...existing,
                ...toReference(data.login),
              };
            },
          },
        });
      }
    },
    onError(error: ApolloError) {
      console.log(error);
    },
  });

  const submit = (event: any) => {
    event.preventDefault();
    form.validateFields({ validateOnly: true }).then(
      (input) => {
        void mutate({
          variables: {
            input: {
              identify: input.identify,
              password: input.password,
            },
          },
        });
      },
      (err) => {
        console.log("Error", err);
      }
    );
  };

  const rememberMeData = JSON.parse(
    localStorage.getItem(localData.rememberMe) || "{}"
  );

  const onCheckRemember = (check: CheckboxChangeEvent) => {
    const checked = check.target.checked;
    form.setFieldValue("remember", [checked]);
    if (checked) {
      localStorage.removeItem(localData.rememberMe);
    }
  };

  return (
    <Card className="ant-card-login" style={{ padding: 10, minWidth: 400 }}>
      <Form
        form={form}
        onSubmitCapture={submit}
        initialValues={{
          identify: rememberMeData?.identify,
          remember: [!!rememberMeData?.checked],
          password: rememberMeData?.password,
        }}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="identify"
          label={t("username_or_email")}
          rules={[
            {
              required: true,
              message: t("username_required_message"),
            },
            {
              type: "email",
              validator: validateEmail,
              message: t("email_invalid_message"),
            },
          ]}
        >
          <Input size="large" suffix={<UserOutlined />} />
        </Form.Item>
        <Form.Item
          name="password"
          label={t("password")}
          rules={[
            {
              required: true,
              message: t("password_required_message"),
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input
            size="large"
            suffix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <div style={{ textAlign: "right", paddingTop: 15 }}>
          <Link href="/auth/forgot">{t("forgot_password")}</Link>
        </div>

        <Row align="middle" style={{ marginTop: 30 }}>
          <Col sm={12}>
            <Form.Item name="remember" valuePropName="remember">
              <Checkbox
                defaultChecked={!!rememberMeData?.checked}
                onChange={onCheckRemember}
                value={true}
              >
                {t("remember_me")}
              </Checkbox>
            </Form.Item>
          </Col>

          <Col sm={12}>
            <Button
              style={{ background: colorTextBase, color: "white" }}
              size="large"
              block
              htmlType="submit"
            >
              {t("connect")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
