import { createBrowserRouter, Navigate } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
import { CooperationPage } from "../pages/admin/CooperationPage";
import { ProjectPage } from "../pages/admin/ProjectPage";
import { CooperationAddPage } from "../pages/admin/CooperationAddPage";
import { ProjectAddPage } from "../pages/admin/ProjectAddPage";
import AuthLayout from "../layouts/AuthLayout";
import { LoginPage } from "../pages/auth/LoginPage";
import PublicLayout from "../layouts/PublicLayout";
import { PublicCooperation } from "../pages/public/PublicCooperation";
import { PublicProjects } from "../pages/public/PublicProjects";
import { PublicBourses } from "../pages/public/PublicBourses";
import { PublicOffers } from "../pages/public/PublicOffers";
import { ScholarshipPage } from "../pages/admin/bourse/BoursePage";
import { OfferPage } from "../pages/admin/OfferPage";
import { BourseAddPage } from "../pages/admin/bourse/BourseAddPage";
import { OfferAddPage } from "../pages/admin/OfferAddPage";
import { CooperationUpdatePage } from "../pages/admin/CooperationUpdatePage";
import { OfferUpdatePage } from "../pages/admin/OfferUpdatePage";
import { ProjectUpdatePage } from "../pages/admin/ProjectUpdatePage";
import { BourseUpdatePage } from "../pages/admin/bourse/BourseUpdatePage";
import PublicBourse from "../pages/public/PublicBourse";
import PublicOffer from "../pages/public/PublicOffer";
import PublicProject from "../pages/public/PublicProject";
import { SettingsLayout } from "../layouts/SettingsLayout";
import { AdministrationPage } from "../pages/admin/settings/AdministrationPage";
import { EstablishmentPage } from "../pages/admin/settings/EstablishmentPage";
import { SettingsOtherPage } from "../pages/admin/settings/SettingsOtherPage";

export const router = createBrowserRouter([
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      { path: "", element: <Navigate to="cooperations" replace /> },
      {
        path: "cooperations",
        element: <CooperationPage />,
      },
      {
        path: "cooperations/add",
        element: <CooperationAddPage />,
      },
      {
        path: "cooperations/:id",
        element: <CooperationUpdatePage />,
      },
      {
        path: "projects",
        element: <ProjectPage />,
      },
      {
        path: "projects/add",
        element: <ProjectAddPage />,
      },
      {
        path: "projects/:id",
        element: <ProjectUpdatePage />,
      },
      //SCHOLARSHIP
      {
        path: "scholarships",
        element: <ScholarshipPage />,
      },
      {
        path: "scholarships/:id",
        element: <BourseUpdatePage />,
      },
      {
        path: "scholarships/add",
        element: <BourseAddPage />,
      },
      //OFFERS
      {
        path: "offers",
        element: <OfferPage />,
      },
      {
        path: "offers/add",
        element: <OfferAddPage />,
      },
      {
        path: "offers/:id",
        element: <OfferUpdatePage />,
      },
      {
        path: "settings",
        element: <SettingsLayout />,
        children: [
          { path: "", element: <Navigate to="administration" replace /> },
          { path: "administration", element: <AdministrationPage /> },
          { path: "establishments", element: <EstablishmentPage /> },
          { path: ":type", element: <SettingsOtherPage /> },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      { path: "", element: <Navigate to="cooperations" replace /> },
      {
        path: "cooperations",
        element: <PublicCooperation />,
      },
      {
        path: "projects",
        element: <PublicProjects />,
      },
      {
        path: "projects/:id",
        element: <PublicProject />,
      },
      {
        path: "scholarships",
        element: <PublicBourses />,
      },
      {
        path: "scholarships/:id",
        element: <PublicBourse />,
      },
      {
        path: "offers",
        element: <PublicOffers />,
      },
      {
        path: "offers/:id",
        element: <PublicOffer />,
      },
    ],
  },
]);
