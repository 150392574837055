import {
  Alert,
  Avatar,
  Button,
  Flex,
  Popconfirm,
  Space,
  Tag,
  Typography,
  theme,
} from "antd";
import "./AppAlert.css";
import {
  DeleteOutlined,
  EditFilled,
  InboxOutlined,
  SelectOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { ReactNode, useCallback } from "react";
import dayjs from "dayjs";
import { truncateText } from "../../utils/formatter";
import { StatusEnum } from "../../graphql";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  title: string;
  details: string;
  date?: string | Date;
  status?: StatusEnum;
  icon?: ReactNode;
  tags?: string[];
  onUpdate?: () => void;
  onDelete?: () => void;
  onArchive?: (status: StatusEnum) => void;
  onOpen?: () => void;
  to?: string;
};
export const AppAlert = ({
  title,
  details,
  icon,
  tags,
  date,
  onUpdate,
  onDelete,
  onArchive,
  status,
  onOpen,
  to,
}: Props) => {
  const {
    token: { colorTextBase },
  } = theme.useToken();

  const getLastButton = useCallback(() => {
    switch (status) {
      case StatusEnum.Archived:
      case StatusEnum.Draft:
        return (
          <Button
            onClick={() => onArchive && onArchive(StatusEnum.Published)}
            style={{ color: "green" }}
            type="text"
            icon={<InboxOutlined />}
          >
            Publier
          </Button>
        );
      case StatusEnum.Published:
        return (
          <Button
            onClick={() => onArchive && onArchive(StatusEnum.Archived)}
            style={{ color: "green" }}
            type="text"
            icon={<InboxOutlined />}
          >
            Archiver
          </Button>
        );
    }
  }, [status, onArchive]);

  const location = useLocation();
  const navigate = useNavigate();

  const onClickAlert = useCallback(() => {
    if (to) {
      navigate(to);
    } else if (onOpen) {
      onOpen();
    }
  }, [onOpen, navigate, to]);

  return (
    <Alert
      onClick={onClickAlert}
      className="app-info"
      style={{
        cursor: `${location.pathname.includes("admin") ? "" : "pointer"}`,
      }}
      message={
        <Flex justify="space-between" wrap="wrap">
          {to ? (
            <Typography.Link href={to} style={{ fontSize: 20, margin: 0 }}>
              {title}
            </Typography.Link>
          ) : (
            <Typography.Title style={{ fontSize: 20, margin: 0 }}>
              {title}
            </Typography.Title>
          )}
          <Space className="actions">
            {location.pathname.includes("admin") ? (
              <>
                <Button
                  style={{ color: "var(--blue-france-500)" }}
                  icon={<EditFilled />}
                  onClick={onUpdate}
                  type="text"
                >
                  Modifier
                </Button>

                <Popconfirm
                  title="Suppression"
                  description="Voulez-vous supprimer cet élément ?"
                  onConfirm={onDelete}
                  okText="Oui"
                  cancelText="Non"
                >
                  <Button icon={<DeleteOutlined />} type="text" danger>
                    Supprimer
                  </Button>
                </Popconfirm>
                {getLastButton()}
              </>
            ) : (
              <Button
                style={{ color: "var(--blue-france-500)" }}
                icon={<SelectOutlined />}
                onClick={onOpen}
                type="text"
              >
                Ouvrir
              </Button>
            )}
          </Space>
        </Flex>
      }
      icon={
        <Avatar
          style={{ background: colorTextBase, color: "white" }}
          size={50}
          icon={icon ? icon : <UserAddOutlined />}
        />
      }
      description={
        <Flex gap={10} vertical>
          <Typography.Paragraph style={{ color: "var(--black)", margin: 0 }}>
            Ajouté le {dayjs(date || new Date()).format("DD/MM/YYYY à HH:mm")}
          </Typography.Paragraph>
          <Space wrap>
            {tags &&
              tags.map((tag, index) => (
                <Tag key={index} style={{ color: "var(--grey-500)" }}>
                  {tag}
                </Tag>
              ))}
          </Space>
          <Typography.Text
            className="text-justity"
            style={{ color: "var(--light-text-color)" }}
          >
            <div dangerouslySetInnerHTML={{ __html: truncateText(details) }} />
          </Typography.Text>
        </Flex>
      }
      type="info"
      showIcon
    />
  );
};
