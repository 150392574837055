import { Button, Popconfirm, Space, TableColumnsType, TableProps } from "antd";
import { useState } from "react";
import {
  EstablishmentDataFragment,
  useRemoveEstablishmentMutation,
} from "../../../graphql";
import { DeleteFilled, EditFilled, MoreOutlined } from "@ant-design/icons";
import { NotificationInstance } from "antd/es/notification/interface";
import { useTranslation } from "react-i18next";

export type EstablishmentRow = Omit<EstablishmentDataFragment, "children"> & {
  parent: Omit<EstablishmentDataFragment, "children">;
};

type TableRowSelection<T> = TableProps<T>["rowSelection"];

type Props = {
  openDrawer: (establishment?: EstablishmentRow) => void;
  api: NotificationInstance;
};

export const useEstablishmentsColumn = ({ openDrawer, api }: Props) => {
  const { t } = useTranslation("index");

  const [removeEsta] = useRemoveEstablishmentMutation({
    onCompleted() {
      api.success({
        message: "Suppression",
        description: "L'université supprimée avec succès!",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    },
    onError(error) {
      api.error({
        message: "Suppression",
        description: `Erreur : ${error?.message}`,
      });
    },
  });

  const columns: TableColumnsType<EstablishmentRow> = [
    {
      title: "Etablissement",
      dataIndex: "parent",
      render: (value) => value.name,
    },
    {
      title: "Pays",
      dataIndex: "town",
      render(value) {
        const region = (value?.type || "").split("_")[0];
        return t(region?.toLowerCase());
      },
    },
    {
      title: "Ville",
      dataIndex: "town",
      render(value) {
        return value?.value;
      },
    },
    {
      title: "",
      dataIndex: "id",
      render(id, record) {
        return (
          <Space size={10}>
            <Button
              onClick={() => openDrawer(record)}
              type="text"
              size="small"
              icon={<EditFilled />}
            />

            <Popconfirm
              title="Attention !"
              description={
                <div>
                  Etes-vous sûr de vouloir supprimer cette université ?<br />
                  Toutes ses coopérations seront définitivement supprimées.
                </div>
              }
              onConfirm={() => removeEsta({ variables: { id } })}
              okText={t("confirm")}
              cancelText={t("cancel")}
              okButtonProps={{ danger: true }}
            >
              <Button danger size="small" icon={<DeleteFilled />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<EstablishmentRow> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return {
    columns,
    rowSelection,
    selectedRowKeys,
  };
};
