import { Card, Col, Form, Input, Row, Select, notification } from "antd";
import { FormInstance } from "antd/es/form";
import ReactQuill from "react-quill";
import { quillModules, quillFormats } from "../../constants/quill-configs";
import { AppImageInput } from "../common/AppMedia";
import {
  CreateOfferInput,
  DictionaryTypeEnum,
  OfferDataFragment,
  StatusEnum,
  useDictionariesQuery,
  useOfferCreateMutation,
  useOfferUpdateMutation,
} from "../../graphql";
import { useEffect, useState } from "react";
import { getStatusMasculinMessage } from "../../utils/status-message";
import { useNavigate } from "react-router-dom";
import { filTerDictionary, filterOption } from "../../utils/build-options";

type Props = {
  form: FormInstance<CreateOfferInput>;
  offer: OfferDataFragment | null | undefined;
  status: StatusEnum;
};

export const OfferForm = ({ form, offer, status }: Props) => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  /**Cooperations types */
  const { data: dictionaryData, loading: dictionalyLoading } =
    useDictionariesQuery({
      variables: {
        types: [
          DictionaryTypeEnum.CooperationDiscipline,
          DictionaryTypeEnum.AgreementLevel,
        ],
      },
    });

  const [logos, setLogos] = useState<number[]>([]);

  const [createOffter, { loading: loadingCreate }] = useOfferCreateMutation({
    onCompleted: () => {
      api.success({
        message: "Nouvel appel à la candidature",
        description: `Appel d'offre crée et ${getStatusMasculinMessage(
          status
        )} avec succès !`,
      });
      setTimeout(() => {
        navigate("/admin/offers");
      }, 500);
    },
    onError: (error) => {
      api.error({
        message: "Ajout d'appel à la candidature",
        description: `Erreur : ${error?.message}`,
      });
    },
    update: (cache, { data }) => {
      if (data?.createOffer) {
        cache.modify({
          fields: {
            offers: (existingRef: any, { toReference }) => {
              return [toReference(data.createOffer), ...existingRef];
            },
          },
        });
      }
    },
  });

  const [updateOffer, { loading: loadingUpdate }] = useOfferUpdateMutation({
    onCompleted: () => {
      api.success({
        message: "Modification",
        description: `Appel à la candidature ${getStatusMasculinMessage(
          status
        )} avec succès !`,
      });
      setTimeout(() => {
        navigate("/admin/offers");
      }, 1000);
    },
    onError: (error) => {
      api.error({
        message: "Modification d'appel à la candidature",
        description: `Erreur : ${error?.message}`,
      });
    },
  });

  const updateForm = (data: OfferDataFragment | undefined | null) => {
    if (data) {
      form.setFieldsValue({
        title: data.title,
        medias: data.medias.map((m) => m.id),
        domains: data.domains.map((d) => d.id),
        levels: data.levels.map((d) => d.id),
        status: data.status,
        description: data.description,
      });
      setLogos(data.medias.map((m) => m.id));
    } else {
      form.setFieldsValue({
        title: "",
        medias: [],
        domains: [],
        levels: [],
        status: StatusEnum.Published,
        description: "",
      });
      setLogos([]);
    }
  };

  const onFinish = (input: CreateOfferInput) => {
    if (loadingCreate || loadingUpdate) {
      return;
    }
    if (!offer) {
      createOffter({
        variables: { input: { ...input, status, medias: logos } },
      });
    } else {
      updateOffer({
        variables: { input: { ...input, status, medias: logos, id: offer.id } },
      });
    }
  };

  useEffect(() => {
    updateForm(offer);
  }, [offer]);

  return (
    <>
      {contextHolder}
      <Card style={{ marginTop: 20 }}>
        <Form
          form={form}
          onFinish={onFinish}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="cooperation-form"
        >
          <Form.Item
            name="title"
            label="Titre"
            rules={[{ required: true, message: "Entrez le titre" }]}
          >
            <Input type="filled" size="large" />
          </Form.Item>

          <Row gutter={10}>
            <Col sm={24} md={12}>
              <Form.Item
                name="domains"
                label="Disciplines"
                rules={[{ required: true, message: "Disciplines requises" }]}
              >
                <Select
                  showSearch
                  size="large"
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="Select ..."
                  filterOption={filterOption}
                  optionFilterProp="children"
                  loading={dictionalyLoading}
                  options={filTerDictionary(
                    DictionaryTypeEnum.CooperationDiscipline,
                    dictionaryData?.dictionaries || []
                  )}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item
                name="levels"
                label="Niveau"
                rules={[{ required: true, message: "Niveau requis" }]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  maxTagCount="responsive"
                  size="large"
                  placeholder="Select ..."
                  filterOption={filterOption}
                  optionFilterProp="children"
                  options={filTerDictionary(
                    DictionaryTypeEnum.AgreementLevel,
                    dictionaryData?.dictionaries || []
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Logos" name="medias">
            <AppImageInput selected={logos} setSelected={setLogos} />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Entrez la description" }]}
          >
            <ReactQuill
              modules={quillModules}
              formats={quillFormats}
              placeholder="description"
              theme="snow"
            />
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
