import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Typography, Flex } from "antd";
import AppSpiner from "./AppSpiner";
import { useNavigate } from "react-router-dom";
import { MediaDataFragment } from "../../graphql";
import { sanitize } from "dompurify";

type Props = {
  loading: boolean;
  title?: string;
  description?: string;
  to: string;
  medias?: MediaDataFragment[];
};
export const CommonDetails = ({
  loading,
  title = "",
  description = "",
  medias = [],
  to,
}: Props) => {
  const navigate = useNavigate();
  const basePath = `${process.env.REACT_APP_API_BASE_URL}/api`;
  if (loading) return <AppSpiner loading={loading} />;
  else if (!title && !description) return <></>;
  return (
    <>
      <Button
        style={{
          border: 0,
          marginBottom: 20,
          height: 50,
          minWidth: 100,
          color: "var(--blue-france-500)",
        }}
        size="large"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate(to)}
      >
        Retour
      </Button>
      <Card style={{ color: "var(--Noir)" }}>
        <Typography.Title style={{ color: "var(--Noir)", fontSize: 20 }}>
          {title}
        </Typography.Title>
        <Flex gap={10} justify="center" wrap="wrap">
          {medias.map((img, index) => (
            <div
              key={index}
              style={{
                height: 100,
                width: 150,
                backgroundImage: `url(${basePath}/${img.path}${img.filename})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
          ))}
        </Flex>
        <div
          className="text-justity"
          dangerouslySetInnerHTML={{ __html: sanitize(description || "") }}
        />
      </Card>
    </>
  );
};
