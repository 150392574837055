import {
  DeleteOutlined,
  EditOutlined,
  InboxOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { theme, Tag, MenuProps, Dropdown } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CooperationDataFragment, StatusEnum } from "../../graphql";
import { useEstablishment } from "../useEstablishment";
import { useCooperation } from "./useCooperation";

export const useCooperationColumn = () => {
  const {
    contextHolder,
    contextHolderModal,
    removeCooperation,
    loadingRemove,
    updateStatus,
    loadingUpdate,
  } = useCooperation({});

  const navigate = useNavigate();

  const {
    token: { colorTextSecondary },
  } = theme.useToken();

  const { t } = useTranslation(["cooperation", "index"]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { getName } = useEstablishment();

  const items: MenuProps["items"] = [
    {
      label: t("modify", { ns: "index" }),
      key: "1",
      icon: <EditOutlined />,
      style: { color: "green" },
    },
    {
      label: t("remove", { ns: "index" }),
      key: "2",
      icon: <DeleteOutlined />,
      style: { color: "red" },
    },
    {
      label: t("achive", { ns: "index" }),
      key: "3",
      icon: <InboxOutlined />,
    },
  ];

  const onClickItem = (key: string, id: number) => {
    switch (key) {
      case "1":
        navigate(`/admin/cooperations/${id}`);
        break;
      case "2":
        removeCooperation(id);
        break;
      case "3":
        updateStatus(id, StatusEnum.Archived);
        break;
    }
  };

  const columns: ColumnsType<CooperationDataFragment> = [
    {
      title: t("title"),
      dataIndex: "title",
    },
    {
      title: "Nombre d'accord",
      dataIndex: "agreements",
      responsive: ["md"],
      render(_, record) {
        return <Tag color="success">{record.agreements.length}</Tag>;
      },
    },
    {
      title: "Etablissement",
      dataIndex: "establishment",
      render(_, record) {
        return (
          <span style={{ color: colorTextSecondary }}>
            {getName(record?.establishment?.id || 0)}
          </span>
        );
      },
    },
    {
      title: t("publication"),
      dataIndex: "createdAt",
      responsive: ["md"],
      render(value: string) {
        return (
          <span style={{ color: colorTextSecondary }}>
            {dayjs(value).format(t("date_short_format", { ns: "index" }))}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      render(value) {
        return (
          <Dropdown
            menu={{ items, onClick: ({ key }) => onClickItem(key, value) }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <MoreOutlined />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return {
    rowSelection,
    columns,
    contextHolder,
    contextHolderModal,
    removeCooperation,
    loadingRemove,
    loadingUpdate,
  };
};
