import { Form, Card, Select, Flex, Row, Col, Alert } from "antd";
import { AppAlert } from "../../components/common/AppAlert";
import { CrownOutlined } from "@ant-design/icons";
import {
  BourseDataFragment,
  DictionaryTypeEnum,
  FilterBourseInput,
  useBoursesQuery,
  useDictionariesQuery,
} from "../../graphql";
import { useCallback, useEffect, useMemo } from "react";
import { filTerDictionary } from "../../utils/build-options";
import AppSpiner from "../../components/common/AppSpiner";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { sanitize } from "dompurify";

const initialValues: FilterBourseInput = {
  types: [],
  disciplines: [],
  levels: [],
};

export const PublicBourses = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation("cooperation");
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, []);

  const {
    loading,
    data: boursesData,
    refetch,
  } = useBoursesQuery({
    variables: { input: initialValues },
    onError(error) {
      console.log(error);
    },
  });

  /**Cooperations types */
  const { data: dictionaryData, loading: dictionalyLoading } =
    useDictionariesQuery({
      variables: {
        types: [
          DictionaryTypeEnum.Scholarship,
          DictionaryTypeEnum.AgreementDomain,
          DictionaryTypeEnum.AgreementLevel,
          DictionaryTypeEnum.PageBourseDesc,
        ],
      },
    });

  const getSelectOptions = useCallback(
    (type: DictionaryTypeEnum) =>
      filTerDictionary(type, dictionaryData?.dictionaries || []),
    [dictionaryData]
  );

  const refreshData = () => {
    refetch({ input: form.getFieldsValue() });
  };

  const getTags = (bourse: BourseDataFragment) => {
    return bourse.disciplines
      .map((d) => d.value)
      .concat(bourse.levels.map((l) => t(l.value)));
  };

  const getDescription = useMemo(() => {
    const desc = dictionaryData?.dictionaries?.find(
      (dic) => dic.type === DictionaryTypeEnum.PageBourseDesc
    )?.value;
    return sanitize(desc || "");
  }, [dictionaryData]);

  return (
    <>
      {getDescription ? (
        <Alert
          message="Les bourses en France"
          description={
            <div dangerouslySetInnerHTML={{ __html: getDescription }} />
          }
          type="info"
          className="text-justity"
          banner
          style={{
            marginBottom: 20,
            background: "white",
            border: "unset",
          }}
          showIcon
        />
      ) : null}
      <Form form={form} layout="vertical" className="public-form">
        <Card size="small">
          <Row gutter={[10, 10]}>
            <Col xs={24} md={8}>
              <Form.Item name="types" label="Type de bourse">
                <Select
                  showSearch
                  onChange={refreshData}
                  maxTagCount="responsive"
                  allowClear
                  placeholder="Séléctionner ..."
                  loading={dictionalyLoading}
                  mode="multiple"
                  optionFilterProp="children"
                  options={getSelectOptions(DictionaryTypeEnum.Scholarship)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item name="disciplines" label={t("domain")}>
                <Select
                  showSearch
                  loading={dictionalyLoading}
                  onChange={refreshData}
                  allowClear
                  placeholder="Séléctionner ..."
                  mode="multiple"
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  options={getSelectOptions(DictionaryTypeEnum.AgreementDomain)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item name="levels" label="Niveau">
                <Select
                  showSearch
                  onChange={refreshData}
                  allowClear
                  placeholder="Séléctionner ..."
                  mode="multiple"
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  options={getSelectOptions(DictionaryTypeEnum.AgreementLevel)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>

      <AppSpiner loading={loading} />

      <Flex vertical gap="middle">
        {(boursesData?.bourses || []).map((d, index) => (
          <AppAlert
            key={index}
            icon={<CrownOutlined />}
            title={d.title}
            details={d.description}
            tags={getTags(d)}
            onOpen={() => navigate(`/scholarships/${d.id}`)}
          />
        ))}
      </Flex>
    </>
  );
};
