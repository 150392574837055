import { useNavigate, useParams } from "react-router-dom";
import { useBourseQuery } from "../../graphql";
import { CommonDetails } from "../../components/common/CommonDetails";

const PublicBourse = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, data } = useBourseQuery({
    variables: {
      id: Number(id),
    },
    skip: !id,
    onError(error) {
      console.log("FIND ERROR", error);
      navigate("/scholarships");
    },
  });

  return (
    <CommonDetails to="/scholarships" loading={loading} {...data?.bourse} />
  );
};

export default PublicBourse;
