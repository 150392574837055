import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Typography,
  Flex,
  Space,
  Button,
  AutoComplete,
} from "antd";

import {
  CreateEstablishmentInput,
  DictionaryTypeEnum,
  useCreateEstablishmentMutation,
  useDictionariesQuery,
  useUpdateEstablishmentMutation,
} from "../../graphql";

import { useEffect, useMemo, useState } from "react";
import { NotificationInstance } from "antd/es/notification/interface";
import { EstablishmentRow } from "../../pages/admin/settings/useEstablishmentColumn";

type Props = {
  establishment: EstablishmentRow | null | undefined;
  setOpen: (open: boolean) => void;
  api: NotificationInstance;
  countries: Array<{ value: string; label: string }>;
  establishments: Array<{ value: string; id: number }>;
};

export const EstablishmentForm = ({
  establishment,
  setOpen,
  api,
  countries,
  establishments,
}: Props) => {
  const defaultValues = {
    country: null as any,
    region: null as any,
    cityId: null as any,
    name: "",
    postalCode: "",
    contactName: "",
    contactPost: "",
    contactEmail: "",
    contactPhone: "",
    link: "",
  };

  const { data: dictionaryData, loading: dictionalyLoading } =
    useDictionariesQuery({
      variables: {
        types: [
          DictionaryTypeEnum.MadagascarCity,
          DictionaryTypeEnum.MadagascarRegion,
          DictionaryTypeEnum.FranceCity,
          DictionaryTypeEnum.FranceRegion,
        ],
      },
    });

  const [form] = Form.useForm<typeof defaultValues>();
  const country = Form.useWatch("country", form);
  const region = Form.useWatch("region", form);
  const name = Form.useWatch("name", form);

  // Université principale
  const [parentId, setParentId] = useState<number | null>(null);

  const suggestionsEstablishments = useMemo(() => {
    return establishments.filter((opt) =>
      opt.value.toLowerCase().includes(`${name}`.toLowerCase())
    );
  }, [name, establishments]);

  const regionsOptions = useMemo(() => {
    return (dictionaryData?.dictionaries || [])
      .filter((dic) => {
        const dicCountry = dic.type.split("_")[0].toLowerCase();
        return (
          country === dicCountry &&
          [
            DictionaryTypeEnum.FranceRegion,
            DictionaryTypeEnum.MadagascarRegion,
          ].includes(dic.type)
        );
      })
      .map((region) => ({ value: region.id, label: region.value }));
  }, [dictionaryData, country]);

  const citiesOptions = useMemo(() => {
    return (dictionaryData?.dictionaries || [])
      .filter((dic) => dic?.parent?.id === region)
      .map((city) => ({ value: city.id, label: city.value }));
  }, [dictionaryData, region]);

  useEffect(() => {
    if (establishment) {
      const country = establishment.town!.type.split("_")[0].toLowerCase();
      form.setFieldsValue({
        cityId: establishment.town!.id,
        country,
        region: establishment.town!.parentId,
        name: establishment.parent.name,
        postalCode: establishment.postalCode,
        contactName: establishment.contactName,
        contactPost: establishment.contactPost,
        contactEmail: establishment.contactEmail,
        contactPhone: establishment.contactPhone,
      });
      setParentId(establishment.parent.id);
    } else {
      form.setFieldsValue({ ...defaultValues });
      setParentId(null);
    }
  }, [establishment]);

  const [createEstablishment, { loading: loadingCreation }] =
    useCreateEstablishmentMutation({
      onCompleted(data) {
        setOpen(false);
        if (data?.createEstablishment) {
          api.success({
            message: "Nouvelle univesité",
            description: "L'université ajoutée avec succès !",
          });
        } else {
          api.error({
            message: "Ajout d'université",
            description: `Echec d'ajout d'université`,
          });
        }
      },
      onError(error) {
        api.error({
          message: "Ajout d'univesité",
          description: `Erreur : ${error?.message}`,
        });
      },
      update: (cache, { data }) => {
        if (data?.createEstablishment) {
          cache.modify({
            fields: {
              users(existing: any, { toReference }) {
                return [toReference(data.createEstablishment), ...existing];
              },
            },
          });
        }
      },
    });

  const [updateEstablishment, { loading: updateLoading }] =
    useUpdateEstablishmentMutation({
      onCompleted() {
        api.success({
          message: "Modification d'université",
          description: "Université modifiée avec succès !",
        });
        setOpen(false);
      },
      onError(error) {
        api.error({
          message: "Modification d'université",
          description: `Erreur : ${error?.message}`,
        });
      },
    });

  const onFinish = (fData: typeof defaultValues) => {
    const input: CreateEstablishmentInput = {
      // Ne pas changer l'université principale si l'opération est mise à jour
      parentId: establishment ? establishment?.parent?.id : parentId,
      cityId: fData.cityId,
      contactPhone: fData.contactPhone || "",
      contactEmail: fData.contactEmail || "",
      contactName: fData.contactName || "",
      contactPost: fData.contactPost || "",
      link: fData.link || "",
      name: fData.name,
      postalCode: fData.postalCode,
    };
    if (establishment) {
      updateEstablishment({
        variables: { input: { ...input, id: establishment.id } },
      });
    } else createEstablishment({ variables: { input } });
  };

  return (
    <>
      <Flex
        style={{ paddingTop: 50, paddingBottom: 50 }}
        justify="space-between"
        gap={10}
      >
        <Typography.Title style={{ fontSize: 20 }}>
          Ajout d'université
        </Typography.Title>
        <Space>
          <Button
            disabled={loadingCreation || updateLoading}
            size="large"
            type="default"
            onClick={() => setOpen(false)}
          >
            Annuler
          </Button>
          <Button
            onClick={() => form.submit()}
            disabled={loadingCreation || updateLoading}
            size="large"
            type="primary"
          >
            Enregistrer
          </Button>
        </Space>
      </Flex>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        className="cooperation-form"
      >
        <Form.Item
          rules={[{ required: true, message: "Nom requis" }]}
          name="name"
          label="Nom de l'université"
        >
          {establishment ? (
            <Input placeholder="Entrer le nom de l'université" />
          ) : (
            <AutoComplete
              options={suggestionsEstablishments}
              onSelect={(_, opt) => setParentId(opt.id)}
              placeholder="Entrer le nom de l'université"
            />
          )}
        </Form.Item>
        <Row gutter={10}>
          <Col sm={24} md={12}>
            <Form.Item
              rules={[{ required: true, message: "Pays" }]}
              name="country"
              label="Pays"
            >
              <Select
                showSearch
                allowClear
                loading={dictionalyLoading}
                size="large"
                placeholder="Select ..."
                options={countries}
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item
              rules={[{ required: true, message: "Region" }]}
              name="region"
              label="Region"
            >
              <Select
                showSearch
                filterOption={false}
                allowClear
                loading={dictionalyLoading}
                size="large"
                placeholder="Select ..."
                options={regionsOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col sm={24} md={12}>
            <Form.Item
              rules={[{ required: true, message: "Ville requise" }]}
              name="cityId"
              label="Ville"
            >
              <Select
                showSearch
                allowClear
                loading={dictionalyLoading}
                size="large"
                placeholder="Select ..."
                options={citiesOptions}
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item name="postalCode" label="Code postal">
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        {/***BEGIN CONTACT */}
        <Row gutter={10}>
          <Col sm={24} md={12}>
            <Form.Item name="contactName" label="Nom et Prenom">
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item name="contactPost" label="Poste">
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col sm={24} md={12}>
            <Form.Item name="contactEmail" label="Email">
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item name="contactPhone" label="Téléphone">
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        {/***END CONTACT */}
        <Form.Item name="link" label="Site Web">
          <Input size="large" />
        </Form.Item>
      </Form>
    </>
  );
};
