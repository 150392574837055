import {
  AlertOutlined,
  BookOutlined,
  DashboardOutlined,
  SettingOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import { Footer } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { AppHeader } from "./AppHeader";
import { useResponsive } from "../hooks/useResponsive";
import { MenuItemType, ItemType } from "antd/es/menu/hooks/useItems";
import "./AdminLayout.css";
import { AdminBarActions } from "./AdminBarActions";

const { Content } = Layout;

const AdminLayout = () => {
  const {
    token: { colorTextBase },
  } = theme.useToken();

  const { t } = useTranslation("index");

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const items: ItemType<MenuItemType>[] = [
    {
      key: "cooperations",
      icon: <DashboardOutlined />,
      label: t("cooperations"),
    },
    {
      key: "projects",
      icon: <BookOutlined />,
      label: t("projects"),
    },
    {
      key: "scholarships",
      icon: <AlertOutlined />,
      label: t("scholarships"),
    },
    {
      key: "offers",
      icon: <SoundOutlined />,
      label: t("offers"),
    },
    {
      key: "settings",
      icon: <SettingOutlined />,
      label: t("settings"),
    },
  ];

  const [collapsed, setCollapsed] = useState(false);

  const { isDesktop } = useResponsive();

  if (isDesktop)
    return (
      <Layout>
        <AppHeader
          children={<AdminBarActions isDesktop={isDesktop} items={items} />}
        />
        <Layout>
          <Sider
            style={{ background: colorTextBase }}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <Menu
              className="menu-main"
              mode="inline"
              defaultSelectedKeys={[pathname.split("/")[2] || "cooperations"]}
              onSelect={({ key }) => navigate(`/admin/${key}`)}
              items={items}
            />
          </Sider>
          <Layout>
            <Content className="main-content">
              <Outlet />
            </Content>
            <Footer style={{ textAlign: "center" }}>
              {t("university_cooperation")} ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    );

  return (
    <Layout>
      <AppHeader
        children={<AdminBarActions isDesktop={isDesktop} items={items} />}
      />
      <Layout>
        <Content className="main-content">
          <Outlet />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Ambassade de france ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
