import { Table } from "antd";
import { CooperationDataFragment } from "../../graphql";
import { useCooperationColumn } from "../../hooks/cooperation/useCooperationColumn";

type Props = {
  rows: CooperationDataFragment[];
  loading: boolean;
};

const CooperationsList = ({ rows, loading }: Props) => {
  const {
    rowSelection,
    columns,
    contextHolder,
    contextHolderModal,
    loadingUpdate,
    loadingRemove,
  } = useCooperationColumn();

  return (
    <>
      {contextHolder}
      {contextHolderModal}
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={rows}
        loading={loading || loadingRemove || loadingUpdate}
      />
    </>
  );
};

export default CooperationsList;
