import React, { useMemo, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Flex,
  Popconfirm,
  Space,
  Table,
  Typography,
  notification,
} from "antd";
import { UserDataFragment, useUsersQuery } from "../../../graphql";
import { useTranslation } from "react-i18next";
import { UserForm } from "../../../components/settings/UserForm";
import { DeleteRowOutlined, PlusOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { ReactComponent as SettingsIcon } from "../../../assets/settings.svg";
import { useAdministrationColumn } from "./useAdministrationColumn";

export const AdministrationPage: React.FC = () => {
  const { t } = useTranslation(["user", "index"]);

  const [user, setUser] = useState<UserDataFragment | undefined>();

  const [search, setSearch] = useState<string>("");

  const { data: usersData, loading: usersLoading } = useUsersQuery();

  const [open, setOpen] = useState(false);

  const openDrawer = (user?: UserDataFragment) => {
    setUser(user);
    setOpen(true);
  };

  const [api, contextHolder] = notification.useNotification();

  const { columns, rowSelection, selectedRowKeys, remove } =
    useAdministrationColumn({
      openDrawer,
      api,
    });

  const users = useMemo(() => {
    return usersData?.users?.filter((u) => {
      const searchLower = search.toLocaleLowerCase();
      const name = u.name.toLowerCase();
      const identify = u.identify.toLowerCase();

      return name.includes(searchLower) || identify.includes(searchLower);
    });
  }, [search, usersData]);

  return (
    <>
      {contextHolder}
      <Flex justify="space-between" align="center" style={{ marginBottom: 15 }}>
        <Typography.Title style={{ fontSize: 25, color: "var(--black)" }}>
          Administration
        </Typography.Title>
        <Space>
          <Typography.Title style={{ fontSize: 25, margin: 0, padding: 0 }}>
            Gestion des rôles
          </Typography.Title>
          <SettingsIcon />
        </Space>
      </Flex>

      <Flex gap="small" justify="space-between">
        <Popconfirm
          title={`Supprimer ${selectedRowKeys.length} utlisateur(s)`}
          description="Etes-vous sûr de tout supprimer définitivement ?"
          onConfirm={() => remove(selectedRowKeys as number[])}
          okText="Oui"
          cancelText="Non"
        >
          <Button
            danger
            style={{
              visibility: selectedRowKeys.length ? "visible" : "hidden",
            }}
            icon={<DeleteRowOutlined />}
          />
        </Popconfirm>

        <Space>
          <Search
            placeholder="Rechercher ..."
            className="app-list-bar-search"
            allowClear
            style={{ maxWidth: 350 }}
            onSearch={(val) => setSearch(val)}
            enterButton
            size="large"
          />

          <Divider
            type="vertical"
            style={{ height: 45, width: 2, background: "var(--grey-300)" }}
          />

          <Button
            onClick={() => openDrawer()}
            icon={<PlusOutlined />}
            type="primary"
            size="large"
          >
            {t("add", { ns: "index" })}
          </Button>
        </Space>
      </Flex>

      <Table
        loading={usersLoading}
        style={{ marginTop: 10 }}
        rowKey="id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={users}
      />
      <Drawer
        placement="right"
        width="auto"
        closable={true}
        onClose={() => setOpen(false)}
        open={open}
      >
        <UserForm api={api} user={user} setOpen={setOpen} />
      </Drawer>
    </>
  );
};
