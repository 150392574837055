import { Col, Dropdown, Menu, Row, Space } from "antd";
import { useState } from "react";
import "../pages/admin/CooperationAddPage.css";
import "./SettingsLayout.css";
import { Outlet, useNavigate } from "react-router-dom";
import { SettingsMenu } from "../types";
import { useResponsive } from "../hooks/useResponsive";
import { DownOutlined } from "@ant-design/icons";

export const SettingsLayout = () => {
  const navigate = useNavigate();
  const items = [
    {
      key: SettingsMenu.administration,
      label: "Administration",
    },
    {
      key: SettingsMenu.cooperation,
      label: "Coopération",
    },
    {
      key: SettingsMenu.agreement,
      label: "Accord",
    },
    {
      key: SettingsMenu.establishments,
      label: "Université",
    },

    {
      key: SettingsMenu.level,
      label: "Niveau",
    },

    {
      key: SettingsMenu.discipline,
      label: "Discipline",
    },

    {
      key: SettingsMenu.domain,
      label: "Domaines d'expertise",
    },
    {
      key: SettingsMenu.scholarship,
      label: "Bourses",
    },
    {
      key: SettingsMenu.project,
      label: "Projets",
    },
  ];

  const [menu, setMenu] = useState("administration");

  const gotTo = (key: string) => {
    navigate(`/admin/settings/${key}`);
    setMenu(key);
  };

  const { isDesktop } = useResponsive();

  const menuProps = {
    items,
    onClick: ({ key }: any) => gotTo(key),
  };

  if (!isDesktop) {
    return (
      <>
        <Dropdown.Button
          icon={<DownOutlined />}
          menu={menuProps}
          trigger={["click"]}
        >
          Liste de paramètres
        </Dropdown.Button>
        <Outlet></Outlet>
      </>
    );
  }

  return (
    <Row>
      <Col xxl={4} xl={6} md={24}>
        <Menu
          onClick={({ key }) => gotTo(key)}
          style={{ width: 256 }}
          selectedKeys={[menu]}
          mode="vertical"
          theme="light"
          className="settings-menu"
          items={items}
        />
      </Col>
      <Col xxl={20} xl={18} md={24}>
        <Outlet></Outlet>
      </Col>
    </Row>
  );
};
