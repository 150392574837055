import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import {
  CreateDictionaryInput,
  DictionaryTypeEnum,
  UpdateDictionaryInput,
  useCreateDictionaryMutation,
  useDictionariesLazyQuery,
  useUpdateDictionaryMutation,
} from "../../graphql";
import { useMemo, useState } from "react";
import { SettingsMenu } from "../../types";
import ReactQuill from "react-quill";
import { quillModules, quillFormats } from "../../constants/quill-configs";
import { useForm } from "antd/es/form/Form";
import Draggable from "react-draggable";
import { useDragDialog } from "../../hooks/useDragDialog";
import { NotificationInstance } from "antd/es/notification/interface";
import { useTranslation } from "react-i18next";

type Props = {
  type?: SettingsMenu;
  api: NotificationInstance;
};

export const SettingsDescription = ({ type, api }: Props) => {
  const { disabled, setDisabled, draggleRef, onStart, bounds } =
    useDragDialog();
  const { t } = useTranslation("index");
  const desEnums = [SettingsMenu.project, SettingsMenu.scholarship];
  const [form] = useForm<UpdateDictionaryInput>();

  const descResume = useMemo(() => {
    return {
      showDes: type && desEnums.includes(type),
      type:
        type === SettingsMenu.project
          ? DictionaryTypeEnum.PageProjectDesc
          : DictionaryTypeEnum.PageBourseDesc,
    };
  }, [type]);

  const [load, { data, loading }] = useDictionariesLazyQuery({
    onCompleted(data) {
      if (data?.dictionaries?.length) {
        setOpen(true);
        form.setFieldValue("value", data.dictionaries.at(0)?.value);
      }
    },
    onError(error) {
      api.error({
        message: `Description`,
        description: `Erreur : ${error?.message}`,
      });
    },
  });

  const [createDictionary, { loading: loadingCreation }] =
    useCreateDictionaryMutation({
      onCompleted() {
        api.success({
          message: "Description",
          description: "Description ajoutée avec succès !",
        });
        setOpen(false);
      },
      onError(error) {
        api.error({
          message: `Description`,
          description: `Erreur : ${error?.message}`,
        });
      },
    });

  const [updateDico, { loading: loadingUpdate }] = useUpdateDictionaryMutation({
    onCompleted() {
      api.success({
        message: "Description",
        description: "Description mise à jour avec succès !",
      });
      setOpen(false);
    },
    onError(error) {
      api.error({
        message: `Description`,
        description: `Erreur : ${error?.message}`,
      });
    },
  });

  const [open, setOpen] = useState(false);

  const shwoDescription = () => {
    if (!descResume.showDes) return;
    setOpen(true);

    load({
      variables: {
        types: [descResume.type],
      },
    });
  };

  const submit = (input: CreateDictionaryInput) => {
    const id = data?.dictionaries?.at(0)?.id;
    if (!id) {
      createDictionary({
        variables: { input: { ...input, type: descResume.type } },
      });
    } else {
      updateDico({
        variables: { input: { ...input, type: descResume.type, id } },
      });
    }
  };

  if (!descResume.showDes) return <></>;
  return (
    <>
      <Button
        loading={loading}
        onClick={shwoDescription}
        size="small"
        icon={<EditOutlined />}
      >
        Editer la description
      </Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        cancelText={t("cancel")}
        okText={t("save")}
        onOk={() => form.submit()}
        okButtonProps={{ loading: loadingUpdate || loadingCreation }}
        style={{ top: 100 }}
        width="50rem"
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
          >
            Editer la description
          </div>
        }
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={submit}
          className="cooperation-form"
        >
          <Form.Item
            name="value"
            rules={[{ required: true, message: "Entrez la description" }]}
          >
            <ReactQuill
              modules={quillModules}
              formats={quillFormats}
              placeholder="description"
              theme="snow"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
