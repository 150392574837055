import { AgreementStatus, StatusEnum } from "../graphql";

export const getStatusMessage = (status: StatusEnum) => {
  switch (status) {
    case StatusEnum.Published:
      return "publiée";
    case StatusEnum.Archived:
      return "achivée";
    case StatusEnum.Draft:
      return "mise en brouillon";
  }
};

export const getStatusMasculinMessage = (status: StatusEnum) => {
  switch (status) {
    case StatusEnum.Published:
      return "publié";
    case StatusEnum.Archived:
      return "achivé";
    case StatusEnum.Draft:
      return "mis en brouillon";
  }
};

export const agreementTagColor = (status: AgreementStatus) => {
  switch (status) {
    case AgreementStatus.Inactif:
      return "red";
    case AgreementStatus.EnVoieDeSignature:
      return "warning";
    default:
      return "success";
  }
};
