import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppAlert } from "../../../components/common/AppAlert";
import { AppListBar } from "../../../components/common/AppListBar";
import { Flex, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { CrownOutlined } from "@ant-design/icons";
import { statusBar } from "../../../constants/status-bar";
import {
  StatusEnum,
  useBourseUpdateMutation,
  useBoursesQuery,
  useRemoveBourseMutation,
} from "../../../graphql";

export const ScholarshipPage = () => {
  const { t } = useTranslation(["program", "index"]);
  const [active, setActive] = useState("0");

  const [api, contextHolder] = notification.useNotification();

  const [search, setSearch] = useState("");

  const { loading, data } = useBoursesQuery({
    variables: {
      input: {
        types: [],
        disciplines: [],
        levels: [],
      },
    },
  });

  const navigate = useNavigate();

  const bourses = useMemo(() => {
    return (data?.bourses || []).filter((c) => {
      const statusOk = c.status === statusBar[active];
      const titleOk = c.title.toLowerCase().includes(search.toLowerCase());
      return statusOk && titleOk;
    });
  }, [data, active, search]);

  const statistics = useMemo(() => {
    return (data?.bourses || []).reduce(
      (acc, cur) => {
        acc[cur.status] += 1;
        return acc;
      },
      {
        [StatusEnum.Published]: 0,
        [StatusEnum.Archived]: 0,
        [StatusEnum.Draft]: 0,
      }
    );
  }, [data]);

  //Update data
  const [updateBourse, { loading: loadingUpdate }] = useBourseUpdateMutation({
    onError: (error) => {
      api.error({
        message: "Modification d'appel à la candidature",
        description: `Erreur : ${error?.message}`,
      });
    },
  });

  const onArchive = (id: number, status: StatusEnum) => {
    updateBourse({ variables: { input: { id, status } } });
  };

  //Deleting data
  const [removeBourse, { loading: loadingRemove }] = useRemoveBourseMutation({
    update: (cache, { data }) => {
      if (data?.removeBourse?.success) {
        cache.modify({
          fields: {
            bourses: (existingRef: any, { readField }) => {
              return existingRef.filter(
                (record: any) =>
                  readField("id", record) !== data.removeBourse.id
              );
            },
          },
        });
      }
    },
  });

  const onRemoveBourse = (id: number) => {
    removeBourse({ variables: { id } });
  };

  return (
    <>
      {contextHolder}
      <h3 style={{ fontSize: 20 }}>{t("scholarships", { ns: "index" })}</h3>
      <AppListBar
        onAdd={() => navigate("/admin/scholarships/add")}
        active={active}
        setActive={setActive}
        onSearch={setSearch}
        statistics={statistics}
      />
      <Flex vertical gap="middle">
        {bourses.map((d, index) => (
          <AppAlert
            key={index}
            icon={<CrownOutlined />}
            title={d.title}
            details={d.description}
            status={d.status}
            onUpdate={() => navigate(`/admin/scholarships/${d.id}`)}
            onArchive={(status) => onArchive(d.id, status)}
            onDelete={() => onRemoveBourse(d.id)}
          />
        ))}
      </Flex>
    </>
  );
};
