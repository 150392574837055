import {
  DeleteFilled,
  FileImageOutlined,
  InboxOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Modal,
  Space,
  Tabs,
  Tag,
  Typography,
  UploadFile,
  UploadProps,
  Image,
  Card,
  Checkbox,
  Empty,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./AppImageInput.css";
import Dragger from "antd/es/upload/Dragger";
import {
  MediaDataFragment,
  useMediaCreateMutation,
  useMediasQuery,
  useRemoveMediaMutation,
} from "../../graphql";

enum Tab {
  import = "import",
  media = "medialibrary",
}

type Props = {
  selected?: number[];
  setSelected?: (selected: number[]) => void;
};

export const AppImageInput = ({
  selected = [],
  setSelected = (selected: number[]) => ({}),
}: Props) => {
  const { t } = useTranslation("index");
  // List file to upload
  const [files, setFiles] = useState<MediaDataFragment[]>([]);
  //Upload dialog
  const [openUpload, setOpenUpload] = useState(false);
  //Tabs of dialog
  const [activeTab, setActiveTab] = useState(Tab.import);
  // List file previews before upload(import tab)
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const {
    data: dataMedias,
    loading: mediasLoading,
    refetch: refetchMedias,
  } = useMediasQuery({});

  const remove = (index: number) => {
    const _files = [...files];
    _files.splice(index, 1);
    setFiles(_files);
  };

  const uploadProps: UploadProps = {
    showUploadList: true,
    name: "file",
    multiple: true,
    listType: "picture-card",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (_, list) => {
      setFileList([
        ...fileList,
        ...list.map((file) => ({
          uid: file.uid,
          name: file.name,
          file,
          url: file.type.includes("image")
            ? URL.createObjectURL(file)
            : undefined,
        })),
      ]);
      return false;
    },
    fileList,
  };

  const [mutate] = useMediaCreateMutation({
    context: {
      hasUpload: true,
    },
    onCompleted: (data) => {
      if (data?.createMedia?.length) {
        setFiles([...files, ...data.createMedia]);
        setSelected([...selected, ...data.createMedia.map((c) => c.id)]);
        refetchMedias();
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [removeMedia] = useRemoveMediaMutation({
    onCompleted(data) {
      if (data?.removeMedia) {
        const idremoved = data?.removeMedia;
        const _files = files.filter((f) => f.id !== idremoved);
        const _medias = selected.filter((s) => s !== idremoved);

        setFiles(_files);
        setSelected(_medias);
      }
    },
    onError(error) {
      console.log(error);
    },
    update: (cache, { data }) => {
      if (data?.removeMedia) {
        cache.modify({
          fields: {
            medias(existing: any, { readField }) {
              return existing.filter(
                (m: any) => data.removeMedia !== readField("id", m)
              );
            },
          },
        });
      }
    },
  });

  const onConfirm = () => {
    setOpenUpload(false);
    if (activeTab === Tab.import && fileList.length > 0) {
      const files = fileList.map((f) => (f as any).file);
      void mutate({ variables: { files } });
      setFileList([]);
    } else {
      setFiles(medias.filter((m) => selected.includes(m.id)));
    }
  };

  const onChange = (key: Tab) => {
    setActiveTab(key);
    if (key === Tab.media) {
      setSelected(files.map((f) => f.id));
      refetchMedias();
    }
  };

  const onChangeSelect = (id: number, checked: boolean) => {
    if (checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((s) => s !== id));
    }
  };

  const openDialog = () => {
    setSelected(files.map((f) => f.id));
    setOpenUpload(true);
  };

  useEffect(() => {
    if (dataMedias?.medias?.length) {
      setFiles(dataMedias.medias.filter((f) => selected.includes(f.id)));
      setActiveTab(Tab.media);
    }
  }, [dataMedias, selected]);

  const basePath = `${process.env.REACT_APP_API_BASE_URL}/api`;

  const medias = dataMedias?.medias || [];

  return (
    <>
      <Typography>
        <pre
          style={{
            minHeight: 50,
            margin: 0,
            display: "flex",
            gap: 5,
            justifyContent: "space-between",
          }}
        >
          <Space wrap>
            {files.map((file, index) => (
              <Tag
                key={index}
                onClose={() => remove(index)}
                style={{
                  display: "flex",
                  width: 100,
                  height: 34,
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: "var(--blue-200)",
                  border: 0,
                }}
                closable
              >
                <div
                  style={{
                    width: 80,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {file.filename}
                </div>
              </Tag>
            ))}
            {!files.length && <label></label>}
          </Space>

          <Button
            onClick={openDialog}
            icon={
              mediasLoading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
              ) : (
                <FileImageOutlined />
              )
            }
          />
        </pre>
      </Typography>
      <Modal
        title={
          <span>
            Séléctionner logo(s){" "}
            {mediasLoading && (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
              />
            )}
          </span>
        }
        centered
        open={openUpload}
        className="logo-modal"
        okText={t("confirm")}
        closable
        onCancel={() => setOpenUpload(false)}
        onOk={onConfirm}
        okButtonProps={{ type: "default" }}
        cancelButtonProps={{ style: { display: "none" }, size: "large" }}
      >
        <Tabs
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          items={[
            {
              key: "import",
              label: t("import"),
              children: (
                <Dragger
                  {...uploadProps}
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Déposez vos fichier pour importer
                  </p>
                  <p className="ant-upload-text" style={{ margin: 0 }}>
                    Ou
                  </p>
                  <Button type="default">Séléctionner des fichiers</Button>
                  <p className="ant-upload-hint">
                    Taille de fichier maximale : 25Mo
                  </p>
                </Dragger>
              ),
            },
            {
              key: "medialibrary",
              label: t("medialibrary"),
              children: medias.length ? (
                <Space size={10} wrap>
                  {medias.map((img, index) => (
                    <Card
                      size="small"
                      key={index}
                      bordered
                      actions={[
                        <DeleteFilled
                          onClick={() =>
                            removeMedia({ variables: { id: img.id } })
                          }
                          key="setting"
                        />,
                        <Checkbox
                          value={img.id}
                          defaultChecked={selected.includes(img.id)}
                          checked={selected.includes(img.id)}
                          onChange={(e) =>
                            onChangeSelect(img.id, e.target.checked)
                          }
                        />,
                      ]}
                    >
                      <Image
                        key={index}
                        width={100}
                        height={90}
                        src={`${basePath}/${img.path}${img.filename}`}
                      />
                    </Card>
                  ))}
                </Space>
              ) : (
                <Empty
                  imageStyle={{ height: 60 }}
                  description={<span>Aucun média</span>}
                >
                  <Button
                    onClick={() => setActiveTab(Tab.import)}
                    type="primary"
                  >
                    Ajouter
                  </Button>
                </Empty>
              ),
            },
          ]}
          onChange={(activeKey: string) => onChange(activeKey as Tab)}
        />
      </Modal>
    </>
  );
};
