import { useTranslation } from "react-i18next";
import CooperationsList from "../../components/cooperation/CooperationList";
import { useMemo, useState } from "react";
import { AppListBar } from "../../components/common/AppListBar";
import { useNavigate } from "react-router-dom";
import { StatusEnum, useCooperationsQuery } from "../../graphql";
import { useEstablishment } from "../../hooks/useEstablishment";
import { statusBar } from "../../constants/status-bar";

export const CooperationPage = () => {
  const { t } = useTranslation(["cooperation", "index"]);
  const [active, setActive] = useState("0");
  const [search, setSearch] = useState("");

  const { data, loading } = useCooperationsQuery();

  const { getName } = useEstablishment();

  const navigate = useNavigate();

  const cooperations = useMemo(() => {
    return (data?.cooperations || []).filter((c) => {
      const statusOk = c.status === statusBar[active];
      const titleOk = c.title.toLowerCase().includes(search.toLowerCase());
      const estaOk = getName(c?.establishment?.id || 0)
        .toLowerCase()
        .includes(search.toLowerCase());
      return statusOk && (titleOk || estaOk);
    });
  }, [data, active, search]);

  const statistics = useMemo(() => {
    return (data?.cooperations || []).reduce(
      (acc, cur) => {
        acc[cur.status] += 1;
        return acc;
      },
      {
        [StatusEnum.Published]: 0,
        [StatusEnum.Archived]: 0,
        [StatusEnum.Draft]: 0,
      }
    );
  }, [data]);

  return (
    <>
      <h3 style={{ fontSize: 20 }}>{t("list_cooperations")}</h3>
      <AppListBar
        onAdd={() => navigate("/admin/cooperations/add")}
        active={active}
        onSearch={setSearch}
        setActive={setActive}
        statistics={statistics}
      />
      <CooperationsList loading={loading} rows={cooperations} />
    </>
  );
};
