import { useCallback } from "react";
import { DictionaryTypeEnum, useEstablishmentsQuery } from "../graphql";

export const useEstablishment = () => {
  const { data: establishmentsData, loading: establishmentsLoading } =
    useEstablishmentsQuery();

  const establishments = establishmentsData?.establishments || [];

  const getName = (id: number) => {
    for (const cur of establishments) {
      const ch = cur.children.find((c) => c.id === id);
      if (ch) {
        return cur.children.length > 1
          ? `${cur.name} ${ch?.town?.value}`
          : cur.name;
      }
    }
    return "";
  };

  const getEstablishmentOptions = useCallback(
    (type: DictionaryTypeEnum) => {
      return (establishments || []).reduce((acc: any[], cur) => {
        const length = cur.children.length;
        return [
          ...acc,
          ...cur.children
            .filter((t) => t?.town?.type === type)
            .map((ch) => ({
              label: length > 1 ? `${cur.name} ${ch?.town?.value}` : cur.name,
              value: ch.id,
              townId: ch.town?.id || 0
            }))
            .sort(function (a, b) {
              return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
            }),
        ];
      }, []);
    },
    [establishments]
  );

  return {
    establishments,
    establishmentsLoading,
    getEstablishmentOptions,
    getName,
  };
};
