import { Spin } from "antd";

type Props = {
  loading: boolean;
};
const AppSpiner = ({ loading }: Props) => {
  if (!loading) return <></>;
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000, // Adjust the z-index as needed
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default AppSpiner;
