import { useNavigate } from "react-router-dom";
import { AppFormBar } from "../../components/common/AppFormBar";
import { Form } from "antd";
import "./CooperationAddPage.css";
import { CooperationCreateInput, StatusEnum } from "../../graphql";
import { useState } from "react";
import { CooperationForm } from "../../components/cooperation/CooperationForm";
import { useTranslation } from "react-i18next";

export const CooperationAddPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("cooperation");
  /***Input */

  const [form] = Form.useForm<CooperationCreateInput>();
  const [status, setStatus] = useState(StatusEnum.Published);

  const submit = (status: StatusEnum) => {
    setStatus(status);
    form.submit(); // Trigger errors
  };

  const onCreatedData = () => {
    setTimeout(() => {
      navigate("/admin/cooperations");
    }, 1500);
  };

  return (
    <>
      <AppFormBar
        onCancel={onCreatedData}
        title={t("add-title")}
        onValidate={submit}
      />

      <CooperationForm
        cooperation={undefined}
        status={status}
        onSaved={onCreatedData}
        form={form}
        submit={submit}
      />
    </>
  );
};
