import "./App.css";
import { ConfigProvider, theme } from "antd";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { Suspense } from "react";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./graphql";

const App = () => {
  const primaryColor = "#000091";
  const disableColor = "rgba(0, 0, 0, 0.25)";
  return (
    <Suspense>
      <ApolloProvider client={apolloClient}>
        <ConfigProvider
          theme={{
            token: {
              borderRadius: 5,
              colorPrimaryBg: "#5151F4",
              colorBgContainerDisabled: disableColor,
              colorTextSecondary: "#3A3A3A",
              colorTextBase: primaryColor,
              colorTextPlaceholder: "#929292",
              colorInfoBorder: primaryColor,
              colorInfo: primaryColor,
            },
            algorithm: theme.defaultAlgorithm,
            components: {
              Select: {
                optionSelectedBg: "#E3E3FD",
              },
            },
          }}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </ApolloProvider>
    </Suspense>
  );
};

export default App;
