import { Form, Card, Select, Flex } from "antd";
import { AppAlert } from "../../components/common/AppAlert";
import { StarOutlined } from "@ant-design/icons";
import {
  DictionaryTypeEnum,
  FilterOfferInput,
  OfferDataFragment,
  useDictionariesQuery,
  useOffersQuery,
} from "../../graphql";
import { filTerDictionary } from "../../utils/build-options";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AppSpiner from "../../components/common/AppSpiner";
import { useTranslation } from "react-i18next";

export const PublicOffers = () => {
  const [form] = Form.useForm<FilterOfferInput>();
  const { t } = useTranslation("index");
  useEffect(() => {
    form.setFieldsValue({
      levels: [],
      domains: [],
    });
  }, []);

  const navigate = useNavigate();

  /**Agreements types */
  const { data: dictionaryData, loading: dictionalyLoading } =
    useDictionariesQuery({
      variables: {
        types: [
          DictionaryTypeEnum.CooperationDiscipline,
          DictionaryTypeEnum.AgreementLevel,
        ],
      },
    });

  const { loading, data, refetch } = useOffersQuery({
    variables: {
      input: {
        levels: [],
        domains: [],
      },
    },
  });

  const refetchData = () => {
    refetch({ input: form.getFieldsValue() });
  };

  const getTags = (offer: OfferDataFragment) => {
    return offer.domains
      .map((d) => d.value)
      .concat(offer.levels.map((l) => t(l.value)));
  };

  return (
    <>
      <Form form={form} layout="vertical" className="public-form">
        <Card>
          <Flex justify="start" gap={10}>
            <Form.Item
              style={{ maxWidth: 398, width: "100%" }}
              name="domains"
              label="Disciplines"
            >
              <Select
                showSearch
                placeholder="Séléctionner ..."
                mode="multiple"
                allowClear
                loading={dictionalyLoading}
                maxTagCount="responsive"
                onChange={refetchData}
                optionFilterProp="children"
                options={filTerDictionary(
                  DictionaryTypeEnum.CooperationDiscipline,
                  dictionaryData?.dictionaries || []
                )}
              />
            </Form.Item>
            <Form.Item
              style={{ maxWidth: 398, width: "100%" }}
              name="levels"
              label="Niveau"
            >
              <Select
                showSearch
                placeholder="Séléctionner ..."
                onChange={refetchData}
                mode="multiple"
                allowClear
                maxTagCount="responsive"
                optionFilterProp="children"
                options={filTerDictionary(
                  DictionaryTypeEnum.AgreementLevel,
                  dictionaryData?.dictionaries || []
                )}
              />
            </Form.Item>
          </Flex>
        </Card>
      </Form>

      <AppSpiner loading={loading} />

      <Flex vertical gap="middle">
        {(data?.offers || []).map((d, index) => (
          <AppAlert
            key={index}
            icon={<StarOutlined />}
            title={d.title}
            details={d.description}
            tags={getTags(d)}
            onOpen={() => navigate(`/offers/${d.id}`)}
          />
        ))}
      </Flex>
    </>
  );
};
