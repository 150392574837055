import { FetchResult, fromPromise, Observable } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { apolloClient } from "../client";
import { RefreshTokenDocument } from "../generated/schema";

const refreshToken = (): Promise<FetchResult> => {
  return apolloClient.mutate({
    mutation: RefreshTokenDocument,
  });
};

export const errorLink = onError(
  ({
    graphQLErrors,
    operation,
    forward,
  }): void | Observable<
    FetchResult<
      Record<string, unknown>,
      Record<string, unknown>,
      Record<string, unknown>
    >
  > => {
    if (!graphQLErrors) {
      return;
    }
    for (const { message, locations, path, extensions } of graphQLErrors) {
      console.error(
        `message: ${message} locations: ${JSON.stringify(
          locations
        )} path: ${JSON.stringify(path)} `,
        extensions.code
      );

      if (
        extensions.code === "UNAUTHENTICATED" &&
        window.location.pathname !== "/auth"
      ) {
        console.error(operation.operationName);

        if (operation.operationName === "RefreshToken") {
          window.location.href = "/auth";
          return;
        }

        return fromPromise(refreshToken()).flatMap((res) => {
          return forward(operation);
        });
      }
    }
  }
);
