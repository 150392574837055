import { useNavigate, useParams } from "react-router-dom";
import { useOfferQuery } from "../../graphql";
import { CommonDetails } from "../../components/common/CommonDetails";

const PublicOffer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, data } = useOfferQuery({
    variables: {
      id: Number(id),
    },
    skip: !id,
    onError(error) {
      console.log("FIND ERROR", error);
      navigate("/offers");
    },
  });

  return <CommonDetails to="/offers" loading={loading} {...data?.offer} />;
};

export default PublicOffer;
