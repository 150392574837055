import { EditFilled, DeleteFilled } from "@ant-design/icons";
import {
  TableColumnsType,
  Tag,
  Space,
  Button,
  Popconfirm,
  TableProps,
} from "antd";
import { t } from "i18next";
import {
  UserStatusEnum,
  UserDataFragment,
  useRemoveUserMutation,
} from "../../../graphql";
import { NotificationInstance } from "antd/es/notification/interface";
import { useState } from "react";

type Props = {
  openDrawer: (user: UserDataFragment) => void;
  api: NotificationInstance;
};
type TableRowSelection<T> = TableProps<T>["rowSelection"];

export const useAdministrationColumn = ({ openDrawer, api }: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<UserDataFragment> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const colors: Record<UserStatusEnum, string> = {
    [UserStatusEnum.Active]: "success",
    [UserStatusEnum.Inactive]: "error",
  };

  const [removeUser] = useRemoveUserMutation({
    onCompleted(data) {
      api.success({
        message: "Suppression",
        description: `Utilisateurs supprimés : ${data?.removeUser?.length}`,
      });
    },
    onError(error) {
      api.error({
        message: "Suppression d'utilisateur",
        description: `Erreur : ${error?.message}`,
      });
    },
    update: (cache, { data }) => {
      if (data?.removeUser) {
        cache.modify({
          fields: {
            users(existing: any, { readField }) {
              return existing.filter(
                (userRef: any) =>
                  !data.removeUser.includes(readField("id", userRef) || 0)
              );
            },
          },
        });
      }
    },
  });

  const remove = (ids: number[]) => {
    removeUser({ variables: { ids } });
  };
  const columns: TableColumnsType<UserDataFragment> = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (value) => `${value}`,
    },
    {
      title: t("role"),
      dataIndex: "role",
    },
    {
      title: "status",
      dataIndex: "status",
      render(value) {
        return <Tag color={colors[value as UserStatusEnum]}>{value}</Tag>;
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      render(id, record) {
        return (
          <Space size={10}>
            <Button
              onClick={() => openDrawer(record)}
              size="small"
              icon={<EditFilled />}
            />

            <Popconfirm
              title={`Supprimer ${record.name || record.identify}`}
              description="Etes-vous sûr de supprimer définitivement cet utilisateur ?"
              onConfirm={() => remove([id])}
              okText="Oui"
              cancelText="Non"
            >
              <Button size="small" icon={<DeleteFilled />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return { columns, rowSelection, selectedRowKeys, remove };
};
