export enum SettingsMenu {
  administration = "administration",

  cooperation = "cooperation",

  establishments = "establishments",

  level = "level",

  discipline = "discipline",

  agreement = "agreement",

  domain = "domain",

  scholarship = "scholarship",

  project = "project",
}
