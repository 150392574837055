import { Layout } from "antd";
import { Outlet } from "react-router";
import "./AdminLayout.css";
import "./AuthLayout.css";
import { AppHeader } from "./AppHeader";

const { Content } = Layout;

const AuthLayout = () => {
  return (
    <Layout>
      <AppHeader />
      <Layout className="auth">
        <Content className="main-content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AuthLayout;
